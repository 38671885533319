<template>
  <div class="h-100">
    <div class="d-flex justify-content-between align-items-center">
      <b-form-group>
        <b-form-select :options="[20, 50, 100]" v-model="pageLength" size="sm" :title="$t('select_the_number')" />
      </b-form-group>
      <b-form-group>
        <b-form-input id="ip-search" :placeholder="$t('search')" type="text" v-model="searchTerm" :lazy="true" size="sm" />
      </b-form-group>
    </div>

    <div class="table-container custom-scrollbar" ref="tableContainer">
      <table>
        <thead class="table-header" v-if="columns.length">
          <tr v-for="i in columns[0].label.length" :key="i">
            <th v-for="(column, colIdx) in columns" :key="column.field" :colIdx="colIdx" :style="{ textAlign: column.type === 'number' ? 'right' : 'left' }" :class="{ 'highlight-title': highlightTitle(colIdx), highlight: column.highlight, 'highlight-header': !column.label[i - 1] }">
              <input class="header-input" type="text" :placeholder="column.placeholder ? $t(column.placeholder[i - 1]) + (label === 'merge' ? '...' : column.placeholder[i - 1] === 'enter_location' ? '*' : column.placeholder[i - 1] === 'enter_item' ? '*' : '') : ''" :value="column.label[i - 1]" @change="$emit('edit-header', { field: column.field, index: i - 1, value: $event.target.value })" :readonly="!column.editable" :style="{ cursor: !column.editable ? 'pointer' : 'text' }" @click="$emit('edit-column', column.field)" />
              <b-button v-if="column.deleteable && i === 1" :title="$t('delete')" class="delete-column" size="sm" variant="outline-danger" @click="$emit('delete-column', column.field, colIdx)">
                <feather-icon icon="Trash2Icon" size="10" class="d-block" />
              </b-button>
            </th>
          </tr>
        </thead>
        <tbody v-if="from !== 0 && to !== 0">
          <tr v-for="(row, index) in filtered" :key="index">
            <td v-for="(column, colIdx) in columns" :key="column.field" :style="{ textAlign: column.type === 'number' ? 'right' : 'left' }" :class="{ 'highlight-title': highlightTitle(colIdx), 'text-danger': formatData(row, column, colIdx) === null, highlight: column.highlight, 'text-secondary': column.field === 'date', 'text-success': column.success || (modalType == 'NA-Modal' && isChangeValue(row, index, column, colIdx)), 'text-warning': isModified(row, column.field) }">
              <span v-for="(text, key) of hightLightValue(row, column, colIdx)" :key="text" :colIdx="colIdx" v-if="text" :class="`${key}`">
                {{ text }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="showing">{{ $t('pagination_info', { from, to, total: itemFilterLength }) }}{{ itemLength > itemFilterLength ? $t('pagination_info_extra', { value: itemLength }) : '' }}</div>
      <b-pagination v-model="pageIndex" :total-rows="itemFilterLength" :per-page="pageLength" class="mb-0"></b-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columns: { type: Array, default: [] },
    // rows: { type: Array, default: [] },
    originRows: { type: Array, default: () => [] },
    newLocation: { type: String, default: '' },
    newItem: { type: String, default: '' },
    itemFilterLength: { type: Number, default: 0 },
    filtered: { type: Array, default: [] },
    itemLength: { type: Number, default: 0 },
    updateDatatable: { type: Number, default: 0 },
    dsType: { type: String, default: null },
    rowsData: { type: Array, default: [] },
    filteredIndex: { type: Array, default: [] },
    modalType: { type: String, default: null },
    action: { type: String, default: null },
    label: { type: String, default: '' },
  },
  filters: {
    na(value) {
      return value === null ? 'NA' : value
    },
  },
  mounted() {
    var self = this
    this.changeTitleNavigation()
    document.addEventListener('keydown', function (event) {
      try {
        let input = document.querySelector('#ip-search')
        let searchTerm = input.value
        if (event.key === 'Enter' && document.activeElement === input) {
          if (self.oldSearchTerm == searchTerm) {
            self.moveHighLight++
          } else {
            self.moveHighLight = 0
          }
          const tabltableContainerBound = self.$refs.tableContainer.getBoundingClientRect()
          setTimeout(() => {
            let domIdxHighLightTitle = document.querySelectorAll(`.table-header tr:nth-child(2) .highlight-title`)
            let domIdxHighLight = document.querySelectorAll(`.hightLightText`)
            if (domIdxHighLightTitle.length) {
              let highlightNowTiltle = domIdxHighLightTitle && domIdxHighLightTitle.length >= self.moveHighLight ? self.moveHighLight : (domIdxHighLightTitle.length % self.moveHighLight) - 1
              let moveDomHighLightTitle = domIdxHighLightTitle[highlightNowTiltle].getBoundingClientRect()
              let colIdxHighLightTitle = domIdxHighLightTitle[highlightNowTiltle].getAttribute('colIdx')
              const scrollLeftTitle = Number(colIdxHighLightTitle) * moveDomHighLightTitle.width - (tabltableContainerBound.width / 2 - moveDomHighLightTitle.width / 2)
              self.$refs.tableContainer.scrollTo({
                left: scrollLeftTitle,
                behavior: 'smooth',
              })
            }
            if (domIdxHighLight.length) {
              let highlightNow = domIdxHighLight && domIdxHighLight.length >= self.moveHighLight ? self.moveHighLight : (domIdxHighLight.length % self.moveHighLight) - 1
              let moveDomHighLight = domIdxHighLight[highlightNow].parentElement.getBoundingClientRect()
              let colIdxHighLight = domIdxHighLight[highlightNow].getAttribute('colIdx')
              domIdxHighLight.forEach((element, index) => {
                if (index === highlightNow) {
                  element.style.background = 'yellow'
                } else {
                  element.style.background = '#ccc'
                }
              })
              const scrollLeft = Number(colIdxHighLight) * moveDomHighLight.width - (tabltableContainerBound.width / 2 - moveDomHighLight.width / 2)
              self.$refs.tableContainer.scrollTo({
                left: scrollLeft,
                behavior: 'smooth',
              })
            }
          }, 300)
          self.oldSearchTerm = searchTerm
        }
      } catch {}
    })
  },
  data() {
    return {
      searchTerm: '',
      pageLength: 20,
      pageIndex: 1,
      oldSearchTerm: '',
      moveHighLight: 0,
    }
  },
  computed: {
    isChange() {
      return this.pageLength + this.pageIndex + this.updateDatatable + this.from + this.to
    },
    from() {
      let from = (this.pageIndex - 1) * this.pageLength + 1
      let maxFrom = Math.floor(this.itemFilterLength / this.pageLength) * this.pageLength + 1
      if (!this.itemFilterLength) maxFrom = 0
      return from > maxFrom ? maxFrom : from
    },
    to() {
      let to = (this.pageIndex - 1) * this.pageLength + this.pageLength
      let maxTo = this.itemFilterLength
      return to > maxTo ? maxTo : to
    },
    locale() {
      return this.$i18n.locale
    },
  },

  watch: {
    searchTerm(value) {
      if (value !== '') this.$emit('getData', this.searchTerm, this.from - 1, this.to, true)
      else this.$emit('getData', this.searchTerm, this.from - 1, this.to, false)
    },
    isChange() {
      this.$emit('getData', this.searchTerm, this.from - 1, this.to, false)
      this.moveHighLight = 0
    },
    locale() {
      this.changeTitleNavigation()
    },
  },
  methods: {
    changeTitleNavigation() {
      try {
        let buttonTitle = document.querySelectorAll('ul > li.page-item')
        if (this.locale === 'en') {
          buttonTitle[0].title = 'First'
          buttonTitle[1].title = 'Prev'
          buttonTitle[buttonTitle.length - 2].title = 'Next'
          buttonTitle[buttonTitle.length - 1].title = 'Last'
        } else {
          buttonTitle[0].title = 'まず'
          buttonTitle[1].title = '前のページ'
          buttonTitle[buttonTitle.length - 2].title = '次の'
          buttonTitle[buttonTitle.length - 1].title = '最後'
        }
      } catch {}
    },
    highlightTitle(colIdx) {
      try {
        let item = this.searchTerm.toLowerCase()
        if (!item || item == '') return false
        let columns = this.columns[colIdx].field.toString().toLowerCase()
        if (columns.lastIndexOf(item) > -1) {
          return true
        }
      } catch {}
      return false
    },
    hightLightValue(row, col, colIdx) {
      try {
        let value = ''
        if (this.dsType === 'timeseries') value = row[colIdx]
        else value = row[col.field]
        if (value === '' || value === null) {
          return {
            beforeText: 'NA',
          }
        }

        value = value.toString()
        let idxSearch = value.toLowerCase().lastIndexOf(this.searchTerm.toLowerCase())
        if (idxSearch > -1) {
          return {
            afterText: value.substr(0, idxSearch),
            hightLightText: this.searchTerm,
            beforeText: value.substr(idxSearch + this.searchTerm.length, value.length - 1),
          }
        }
        return {
          beforeText: value,
        }
      } catch {
        return {
          beforeText: 'NA',
        }
      }
    },
    formatData(row, col, colIdx) {
      try {
        let value = ''
        if (this.dsType === 'timeseries') value = row[colIdx] === '' || row[colIdx] === 'NA' ? null : row[colIdx]
        else value = row[col.field]
        return value
      } catch {
        return null
      }
    },
    isModified(modifiedRow, columnField) {
      let isModified = false
      if (this.originRows.length) {
        const originRow = this.originRows.find((row) => row.date === modifiedRow.date)
        if (originRow) {
          isModified = originRow[columnField] !== modifiedRow[columnField]
        }
      }
      return isModified
    },
    scrollRight() {
      this.$refs.tableContainer.scrollLeft = this.$refs.tableContainer.scrollWidth
    },
    isChangeValue(row, rowIdx, col, colIdx) {
      let check = false
      try {
        let oldVal = null
        let newVal = null
        if (this.dsType === 'timeseries') {
          oldVal = this.rowsData[this.filteredIndex[rowIdx]][colIdx]
          newVal = row[colIdx]
        } else {
          oldVal = this.rowsData[this.filteredIndex[rowIdx]][col.field]
          newVal = row[col.field]
        }
        if (newVal != null && oldVal != newVal && this.action != 'original') check = true
        else check = false
      } catch {}
      return check
    },
  },
}
</script>

<style scoped lang="scss">
.table-container {
  height: calc(100vh - 250px);
  overflow: auto;
  margin-bottom: 1rem;
}

table {
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
  padding-right: 1px;
}

th {
  border: 1px solid #ebe9f1;
  padding: 6px 10px;
  white-space: nowrap;
  cursor: pointer;
  position: sticky;
  background-color: #f8f8f8;
}

.table-header tr:nth-child(1) th {
  top: 0;
}
.table-header tr:nth-child(2) th {
  top: 34px;
}
.table-header tr:nth-child(3) th {
  top: 68px;
}
.table-header tr:nth-child(4) th {
  top: 102px;
}

th:first-child {
  position: sticky;
  left: 0px;
  background-color: #f8f8f8;
  z-index: 2;
}

td {
  border: 1px solid #ebe9f1;
  padding: 6px 10px;
  white-space: nowrap;
}

td:first-child {
  position: sticky;
  left: 0px;
  background-color: #ffffff;
  z-index: 1;
}

/* td.is-nan {
  box-shadow: inset 0 0 5px 0 #5e587380;
} */

.showing {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

th .header-input {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  color: inherit;
  text-align: inherit;
  width: 110px;
}

button.edit-column {
  position: absolute;
  top: -9px;
  right: 22px;
  padding: 3px;
}

button.delete-column {
  position: absolute;
  top: 0;
  right: 0;
  padding: 3px;
}

.highlight {
  border-left-color: #28c76f !important;
  border-right-color: #28c76f !important;
}
.highlight-header {
  text-align: left !important;
}
thead tr:first-child .highlight {
  border-top-color: #28c76f !important;
}
tbody tr:last-child .highlight {
  border-bottom-color: #28c76f !important;
}

/* DARK */
.dark-layout th {
  border-color: #3b4253;
  background-color: #343d55;
}

.dark-layout th:first-child {
  background-color: #343d55;
}

.dark-layout td {
  border-color: #3b4253;
}

.dark-layout td:first-child {
  background-color: #283046;
}

/* .dark-layout td.is-nan {
  box-shadow: inset 0 0 5px 0 #000000aa;
} */
.afterText {
  margin-right: -3px;
}
.hightLightText {
  background-color: #ccc;
  border-radius: 2px;
  margin-right: -3px;
  display: inline-block;
}
.custom-body.modal-body {
  color: inherit !important;
}
th,
th input {
  cursor: default !important;
}
.highlight-title {
  /* border-top: 0.5px solid red !important; */
  border-left: 1px solid rgb(13, 160, 218) !important;
  border-right: 1px solid rgb(13, 160, 218) !important;
}
.table-header tr {
  &:nth-child(1) .highlight-title {
    border-top: 1px solid rgb(13, 160, 218) !important;
  }
}
table tbody {
  tr {
    &:last-child .highlight-title {
      border-bottom: 1px solid rgb(13, 160, 218) !important;
    }
  }
}
.dark-layout {
  .hightLightText {
    color: black !important;
  }
}
</style>
