<template>
  <div class="h-100 common-datasource-tools">
    <!-- <b-modal size="xl" title="NA Value Tool" centered ok-only :ok-title="$t('save')" :hide-footer="true" ref="naModal" :body-class="'custom-body'" button-size="sm">
    <template #modal-header> -->
    <div class="tools-header">
      <b-form-group class="mr-1 mb-0">
        <TimeseriesSelect v-model="selected" />
      </b-form-group>
      <b-form-group class="mb-0">
        <label class="text-primary tool-custom-label">{{ $t('offset') }}</label>
        <b-form-input :placeholder="$t('offset')" type="number" min="0" :max="itemLength ? itemLength - 1 : 0" size="sm" v-model.number="offset" lazy style="height: 28px" />
      </b-form-group>
      <b-form-group class="ml-auto mb-0">
        <b-button variant="success" size="sm" style="padding: 14px 28px" :disabled="!offset" @click="save">{{ $t('save') }}</b-button>
      </b-form-group>
    </div>

    <!-- <button type="button" class="close" @click="close">&times;</button>
    </template> -->
    <div class="tools-body">
      <DatatableBody :modalType="null" :action="null" :rowsData="null" :filteredIndex="null" :columns="columns" :dsType="datasourceType" :updateDatatable="updateDatatable" @getData="getDataTable" :itemFilterLength="itemFilterLength" :filtered="filteredData" :itemLength="itemLength" />
      <!-- </b-modal> -->
    </div>
  </div>
</template>

<script>
const Big = require('big.js');
const _ = require('lodash');
import { v4 as uuidv4 } from 'uuid';
import DatatableBody from '../tools/DatatableBody.vue';
import TimeseriesSelect from './TimeseriesSelect.vue';
import FileMixin from '@/mixins/FileMixin.js';
import { ACTION_LOG } from '@/constants/actionLog';

export default {
  components: { DatatableBody, TimeseriesSelect },
  mounted() {
    this.rows = [];
    this.converted = [];
  },
  mixins: [FileMixin],
  data() {
    return {
      selected: null,
      columns: [],
      offset: null,
      itemFilterLength: 0,
      itemLength: 0,
      filteredData: [],
      dataFilterStore: [],
      updateDatatable: 0, //update view datatableBody when rows change(add column, ...)
      datasourceType: '',
    };
  },
  computed: {
    datasources() {
      return this.$store.state.datasource.datasources;
    },
    options() {
      return this.datasources.filter((d) => d.type === 'timeseries').map((d) => ({ value: d.id, label: d.name }));
    },
    datasource() {
      let datasource = this.datasources.find((d) => d.id === this.selected);
      if (!datasource) return null;
      return { ...datasource, ...this.$db[datasource.id] };
    },
    initProject() {
      return this.$store.state.ecoplot.initProject;
    },
  },
  watch: {
    async datasource(datasource) {
      if (datasource) {
        this.offset = 0;
      } else {
        this.offset = null;
      }
      await this.init();
      this.convert();
    },
    offset() {
      this.convert();
    },
    initProject() {
      this.rows = [];
    },
  },
  methods: {
    filtered(dataFilter, searchTerm) {
      let filtered = dataFilter.filter((row) => {
        let valid = false;
        if (this.datasourceType !== 'timeseries') {
          for (const key in row) {
            let value = row[key] === null ? 'NA' : row[key].toString();
            if (value.toLowerCase().includes(searchTerm.toLowerCase())) {
              valid = true;
              break;
            }
          }
        } else {
          for (let i = 0; i < row.length; i++) {
            let value = row[i] === null ? 'NA' : row[i].toString();
            if (value.toLowerCase().includes(searchTerm.toLowerCase())) {
              valid = true;
              break;
            }
          }
        }
        return valid;
      });
      return filtered;
    },

    getDataTable(searchTerm, from, to, isGetDataToSearch) {
      if (searchTerm === '') {
        this.itemFilterLength = this.itemLength;
        this.filteredData = this.filtered(this.converted.slice(+from, +to), searchTerm);
      } else {
        if (isGetDataToSearch) this.dataFilterStore = this.filtered([...this.rows], searchTerm);
        if (!this.dataFilterStore.length) {
          from = from === -1 ? 0 : from;
          to = to === 0 ? 20 : to;
          this.itemFilterLength = this.itemLength;
          this.filteredData = this.filtered(this.rows.slice(+from, +to), '');
        } else {
          this.itemFilterLength = this.dataFilterStore.length;
          this.filteredData = this.dataFilterStore.slice(+from, +to);
        }
      }
    },
    // open() {
    //   this.$refs.naModal.show();
    // },
    // close() {
    //   this.$refs.naModal.hide();
    // },
    transpose(matrix) {
      return matrix[0].map((_, columnIndex) => matrix.map((row) => row && row[columnIndex] != null ? row[columnIndex] : null));
    },
    async init() {
      if (!this.datasource) {
        this.columns = [];
        this.rows = [];
        this.itemFilterLength = 0;
        this.itemLength = 0;
        this.filteredData = [];
        this.dataFilterStore = [];
        this.updateDatatable = 0;
        return;
      }

      if (this.datasource.type === 'timeseries') {
        let items = this.datasource.items;
        let columns = [{ label: this.datasource.header, field: 'date' }];
        let rowsOriginal = {};
        let rowsConvert = {};
        rowsOriginal = await this.selectAll(this.datasource.id);
        rowsConvert['dates'] = rowsOriginal['dates'];
        for (const key in this.$db[this.datasource.id].columns) {
          let [location, item] = key.split('*');
          rowsConvert[key.replace('*', '-')] = rowsOriginal[key.replace('*', '-')];
          columns.push({ label: this.datasource.header.length === 3 ? [location, item, this.datasource.columns[key].unit] : [location, this.datasource.columns[key].autoGroup, item, this.datasource.columns[key].unit], field: key, type: 'number' });
        }
        this.columns = columns;
        this.rows = this.transpose(Object.values(rowsConvert));
        this.itemLength = this.rows.length;
        this.datasourceType = 'timeseries';
      }
    },
    convert() {
      if (!this.datasource) {
        this.converted = [];
        return;
      }

      // original => trả về luôn mảng gốc
      if (!this.offset) {
        this.converted = _.cloneDeep(this.rows);
        this.itemLength = this.converted.length;
        this.updateDatatable++;
        return;
      }

      let converted = [];

      // các cột cần phải tính toán
      // let columns = this.columns.map((c) => c.field).filter((c) => c !== 'date')

      let colsData = this.transpose(this.rows);
      for (let i = 0; i < this.rows.length - this.offset; i++) {
        let row = [];
        row.push(this.rows[i][0]);
        for (let j = 1; j < colsData.length; j++) {
          let value;
          try {
            value = Big(Number(colsData[j][i + this.offset])).minus(Number(colsData[j][i]));
          } catch {
            value = colsData[j][i + this.offset] - colsData[j][i];
          }
          row.push(value);
        }
        converted.push(row);
      }
      this.converted = converted;
      this.itemLength = this.converted.length;
      this.updateDatatable++;
    },
    async save() {
      // convert array of row object to 1 datasource object and add to store
      const id = uuidv4();
      const name = `${this.datasource.name.slice(0, -4)}_offset-${this.offset}.csv`;
      const type = this.datasource.type;
      const dates = this.converted.map((r) => r[0]);
      const columns = this.datasource.columns;
      const encoding = this.datasource.encoding;
      const storage_mode = 'RAM';
      const locations = this.datasource.locations;
      const autoGroups = this.datasource.autoGroups;
      const header = this.datasource.header;
      const ITEM_IDX = this.columns[0].label.findIndex((c) => c === 'ITEM');
      const UNIT_IDX = this.columns[0].label.findIndex((c) => c === 'UNIT');
      let data = {};
      const keysCol = Object.keys(columns);
      for (let i = 0; i < keysCol.length; i++) {
        for (let j = 0; j < this.converted.length; j++) {
          if (!data[keysCol[i].replace('*', '-')]) data[keysCol[i].replace('*', '-')] = [];
          data[keysCol[i].replace('*', '-')].push(this.converted[j][i + 1]);
        }
      }

      // Build Items
      let items = {};
      let itemArray = [...new Set(this.columns.slice(1).map((column) => column.label[ITEM_IDX]))];
      itemArray.forEach((item) => {
        if (!item) return;
        let obj = { min: Infinity, max: -Infinity, unit: '' };
        // Min & max
        for (const key in data) {
          if (key.endsWith(`-${item}`)) {
            for (let i = 0; i < data[key].length; i++) {
              if (data[key][i] !== null && data[key][i] < obj.min) obj.min = data[key][i];
              if (data[key][i] !== null && data[key][i] > obj.max) obj.max = data[key][i];
            }
          }
        }
        // Unit
        let index = this.columns.findIndex((column) => column.label[ITEM_IDX] === item);
        obj.unit = this.columns[index].label[UNIT_IDX];
        items[item] = obj;
      });

      let datasourceObject = { id, name, type, columns, locations, items, dates, encoding, storage_mode, data, csvData: this.converted, autoGroups, header };
      await this.storeData(datasourceObject);
      const file = await this.createObjectFile(datasourceObject);
      datasourceObject.file = file;
      datasourceObject.size = file.size;
      await this.addDatasource(datasourceObject);
      this.updateDatatable++;
      let messageTitle = 'create_from_stationarity';
      let message = this.$t(messageTitle, { move: name });
      this.log.info({ message, id: ACTION_LOG[7].id, messageTitle, move: name });
      // this.log.info({ message: `Create and add datasource '${name}' from datasource tool [Stationarity]`, id: ACTION_LOG[7].id });
      // this.close();
    },
  },
};
</script>

<style>
.tool-custom-radio label {
  line-height: 14px !important;
  padding: 6px 14px !important;
}

.tool-custom-label {
  display: block;
  line-height: 10px;
  margin-bottom: 3px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
}
</style>
