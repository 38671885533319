<template>
  <!-- <b-modal size="xl" title="Formula Tool" centered ok-only :hide-footer="true" :ok-title="$t('save')" ref="formulaModal" :modal-class="'formula-modal'" :body-class="'custom-body'" button-size="sm" @show="resetState">
    <template #modal-header> -->
  <div class="h-100 common-datasource-tools formula-modal">
    <div class="tools-header">
      <b-form-group class="mr-1 mb-0">
        <TimeseriesSelect v-model="selected" />
      </b-form-group>
      <b-form-group class="mb-0 mr-1">
        <label class="text-primary tool-custom-label text-nowrap">{{ $t('add_column') }}</label>
        <b-button variant="outline-success" size="sm" style="padding: 3px 7px" :disabled="!datasource" @click="addColumn">
          <component :is="require('/static/images/tools/formula/add-column.svg').default" :style="{ width: '20px', height: '20px' }" />
        </b-button>
      </b-form-group>
      <b-form-group class="mb-0">
        <label class="text-primary tool-custom-label">{{ $t('file_name') }}</label>
        <b-form-input :placeholder="$t('file_name')" v-model.trim="filename" size="sm" style="height: 28px" />
      </b-form-group>
      <b-form-group class="ml-auto mb-0">
        <b-button variant="success" size="sm" style="padding: 14px 28px" :disabled="!datasource || !filename" @click="save">{{ $t('save') }}</b-button>
      </b-form-group>
    </div>
    <!-- <button type="button" class="close" @click="close">&times;</button>
    </template> -->
    <div class="tools-body">
      <div class="d-flex h-100">
        <div style="flex-grow: 1; min-width: 0">
          <DatatableBody :modalType="null" :action="null" :rowsData="null" :filteredIndex="null" ref="datatableBody" :columns="columns" :updateDatatable="updateDatatable" :dsType="datasourceType" @getData="getDataTable" :itemFilterLength="itemFilterLength" :filtered="filteredData" :itemLength="itemLength" @edit-header="editHeader" @edit-column="editColumn" @delete-column="deleteColumn" />
        </div>

        <div style="width: 1px; margin: 0 1rem; flex-shrink: 0" class="bg-secondary"></div>

        <div style="overflow-y: auto" class="calculator custom-scrollbar">
          <div class="d-flex flex-column h-100">
            <div>
              <div style="margin-right: 0; width: fit-content">
                <b-form-group>
                  <b-form-checkbox v-model="tratNAas0" style="height: 30px">{{ $t('treat_NA_as_0') }}</b-form-checkbox>
                </b-form-group>
              </div>
              <div class="calculation-area bg-light-secondary" :style="{ borderColor: error ? '#ea5455' : 'transparent' }">
                <component v-for="math in maths" :is="math.type" :key="math.id" ref="maths" :value="math.value" @input="changeValue(math.id, $event)" :columns="columns.filter((c) => !['date', editing].includes(c.field))"></component>
                <button :title="$t('add')" class="add-operand bg-light-primary" @click="addMath('operand')">+</button>
                <div class="error-message text-danger">{{ error }}</div>
              </div>
            </div>
            <div class="operation-area">
              <b-button variant="flat-danger" size="sm" @click="clearMath">C</b-button>
              <b-button variant="flat-secondary" class="text-danger" size="sm" @click="deleteMath">
                <component :is="require('/static/images/tools/formula/delete.svg').default" :style="{ width: '16px', height: '16px' }" />
              </b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('open-parentheses')" class="text-primary">(</b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('close-parentheses')" class="text-primary">)</b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('percent-operator')">%</b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('factorial-operator')">!</b-button>
            </div>
            <div class="operation-area">
              <b-button variant="flat-secondary" size="sm" @click="addMath('seven-number')" :style="{ color: isDark ? '#d0d2d6' : '#444' }">7</b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('eight-number')" :style="{ color: isDark ? '#d0d2d6' : '#444' }">8</b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('nine-number')" :style="{ color: isDark ? '#d0d2d6' : '#444' }">9</b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('divide-operator')" class="text-primary">/</b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('square-operator')">
                <component :is="require('/static/images/tools/formula/square.svg').default" :style="{ width: '16px', height: '16px' }" />
              </b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('sin-operator')">sin</b-button>
            </div>
            <div class="operation-area">
              <b-button variant="flat-secondary" size="sm" @click="addMath('four-number')" :style="{ color: isDark ? '#d0d2d6' : '#444' }">4</b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('five-number')" :style="{ color: isDark ? '#d0d2d6' : '#444' }">5</b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('six-number')" :style="{ color: isDark ? '#d0d2d6' : '#444' }">6</b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('multiply-operator')" class="text-primary">*</b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('power-2-operator')">x<sup>2</sup></b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('cos-operator')">cos</b-button>
            </div>
            <div class="operation-area">
              <b-button variant="flat-secondary" size="sm" @click="addMath('one-number')" :style="{ color: isDark ? '#d0d2d6' : '#444' }">1</b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('two-number')" :style="{ color: isDark ? '#d0d2d6' : '#444' }">2</b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('three-number')" :style="{ color: isDark ? '#d0d2d6' : '#444' }">3</b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('minus-operator')" class="text-primary">-</b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('power-n-operator')">x<sup>y</sup></b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('tan-operator')">tan</b-button>
            </div>
            <div class="operation-area">
              <b-button variant="flat-secondary" size="sm" @click="addMath('pi-operator')">&pi;</b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('zero-number')" :style="{ color: isDark ? '#d0d2d6' : '#444' }">0</b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('point-number')">.</b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('add-operator')" class="text-primary">+</b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('absolute-operator')">|x|</b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('log-operator')">log</b-button>
            </div>
            <div class="operation-area">
              <b-button variant="flat-secondary" size="sm" @click="addMath('standardize')" style="width: 114px">standardize</b-button>
              <b-button variant="flat-secondary" size="sm" @click="addMath('normalize')" style="width: 114px">normalize</b-button>
              <b-button variant="success" size="sm" @click="equal" style="width: 114px">=</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- </b-modal> -->
</template>

<script>
const _ = require('lodash')
import Vue from 'vue'
import ScaleMixin from '@/mixins/ScaleMixin'
import DatatableBody from '../tools/DatatableBody.vue'
import TimeseriesSelect from './TimeseriesSelect.vue'
import FileMixin from '@/mixins/FileMixin.js'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import { v4 as uuidv4 } from 'uuid'
import { compile, round } from 'mathjs'
import { scaleNormalize, scaleStandardize } from '@/utilities/NumberUtility.js'
import { ThemeConfig } from '@/mixins/ThemeMixin.js'
import { ACTION_LOG } from '@/constants/actionLog'

// #region Formula Components
Vue.component('open-parentheses', {
  template: '<span>(</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return '('
    },
  },
})

Vue.component('operand', {
  template: `
    <div style="display: inline-block">
      <select v-model="selected" class="bg-light-primary" :style="{ width: width + 'px' }">
        <option v-for="column in columns" :key="column.field" :value="column.field">{{ column.label[0] }} - {{ column.label[2] }}</option>
      </select>
      <select class="bg-light-primary d-none" ref="tempSelect">
        <option>{{ selectedText }}</option>
      </select>
    </div>
  `,
  props: ['value', 'columns'],
  mounted() {
    this.$nextTick(() => {
      this.updateWidth()
    })
  },
  data() {
    return {
      width: 100,
    }
  },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    selectedText() {
      if (!this.selected) return null
      let selected = this.columns.find((c) => c.field === this.selected)
      if (!selected) return null
      return `${selected.label[0]} - ${selected.label[1]}`
    },
  },
  watch: {
    selected() {
      this.$nextTick(() => {
        this.updateWidth()
      })
    },
  },
  methods: {
    updateWidth() {
      if (!this.selected) return
      this.$refs.tempSelect.classList.remove('d-none')
      this.width = this.$refs.tempSelect.offsetWidth
      this.$refs.tempSelect.classList.add('d-none')
    },
    toString() {
      let encoded = this.selected
        .replaceAll('*', '-')
        .replaceAll('-', '_')
        .split('')
        .map((c) => c.charCodeAt(0))
        .join('')
      return this.selected ? `_${encoded}_` : ''
    },
  },
})

Vue.component('close-parentheses', {
  template: '<span>)</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return ')'
    },
  },
})

Vue.component('add-operator', {
  template: '<span>+</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return '+'
    },
  },
})

Vue.component('minus-operator', {
  template: '<span>-</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return '-'
    },
  },
})

Vue.component('multiply-operator', {
  template: '<span>*</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return '*'
    },
  },
})

Vue.component('divide-operator', {
  template: '<span>/</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return '/'
    },
  },
})

Vue.component('square-operator', {
  template: '<span>sqrt(</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return 'sqrt('
    },
  },
})

Vue.component('power-2-operator', {
  template: '<span>^2</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return '^2'
    },
  },
})

Vue.component('power-n-operator', {
  template: '<span>^</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return '^'
    },
  },
})

Vue.component('absolute-operator', {
  template: '<span>abs(</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return 'abs('
    },
  },
})

Vue.component('sin-operator', {
  template: '<span>sin(</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return 'sin('
    },
  },
})

Vue.component('cos-operator', {
  template: '<span>cos(</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return 'cos('
    },
  },
})

Vue.component('tan-operator', {
  template: '<span>tan(</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return 'tan('
    },
  },
})

Vue.component('log-operator', {
  template: '<span>log(</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return 'log('
    },
  },
})

Vue.component('standardize', {
  template: '<span>standardize_</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return 'STANDARD_'
    },
  },
})

Vue.component('normalize', {
  template: '<span>normalize_</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return 'NORMAL_'
    },
  },
})

Vue.component('seven-number', {
  template: '<span>7</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return '7'
    },
  },
})

Vue.component('eight-number', {
  template: '<span>8</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return '8'
    },
  },
})

Vue.component('nine-number', {
  template: '<span>9</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return '9'
    },
  },
})

Vue.component('four-number', {
  template: '<span>4</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return '4'
    },
  },
})

Vue.component('five-number', {
  template: '<span>5</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return '5'
    },
  },
})

Vue.component('six-number', {
  template: '<span>6</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return '6'
    },
  },
})

Vue.component('one-number', {
  template: '<span>1</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return '1'
    },
  },
})

Vue.component('two-number', {
  template: '<span>2</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return '2'
    },
  },
})

Vue.component('three-number', {
  template: '<span>3</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return '3'
    },
  },
})

Vue.component('pi-operator', {
  template: '<span>&pi;</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return 'pi'
    },
  },
})

Vue.component('zero-number', {
  template: '<span>0</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return '0'
    },
  },
})

Vue.component('point-number', {
  template: '<span>.</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return '.'
    },
  },
})

Vue.component('percent-operator', {
  template: '<span>%</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return '%'
    },
  },
})

Vue.component('factorial-operator', {
  template: '<span>!</span>',
  props: ['value', 'columns'],
  methods: {
    toString() {
      return '!'
    },
  },
})
// #endregion

export default {
  components: { DatatableBody, TimeseriesSelect, ToastificationContent },
  mixins: [ScaleMixin, ThemeConfig, FileMixin],
  mounted() {
    this.rows = []
  },
  data() {
    return {
      tratNAas0: false,
      selected: null,
      columns: [],
      // rows: [],
      filename: '',
      maths: [],
      editing: null,
      addedColumn: [],
      error: null,

      itemFilterLength: 0,
      itemLength: 0,
      filteredData: [],
      dataFilterStore: [],
      updateDatatable: 0, //update view datatableBody when rows change(add column, ...)
      datasourceType: '',
      rowEditingIndex: null,
    }
  },
  computed: {
    datasources() {
      return this.$store.state.datasource.datasources
    },
    options() {
      return this.datasources.filter((d) => d.type === 'timeseries').map((d) => ({ value: d.id, label: d.name }))
    },
    datasource() {
      let datasource = this.datasources.find((d) => d.id === this.selected)
      if (!datasource) return null
      return { ...datasource, ...this.$db[datasource.id] }
    },
    datasourceTools() {
      return this.$store.state.map.datasourceTools
    },
  },
  watch: {
    async selected() {
      await this.init()
      this.updateDatatable++
    },
    editing(editing) {
      let columns = []
      this.columns.forEach((c) => {
        columns.push({ ...c, editable: c.field === editing, highlight: c.field === editing })
      })
      this.columns = columns
    },
    datasourceTools() {
      this.resetState()
    },
  },
  methods: {
    filtered(dataFilter, searchTerm) {
      let filtered = dataFilter.filter((row) => {
        let valid = false
        if (this.datasourceType !== 'timeseries') {
          for (const key in row) {
            let value = row[key] === null ? 'NA' : row[key].toString()
            if (value.toLowerCase().includes(searchTerm.toLowerCase())) {
              valid = true
              break
            }
          }
        } else {
          for (let i = 0; i < row.length; i++) {
            let value = row[i] === null ? 'NA' : row[i].toString()
            if (value.toLowerCase().includes(searchTerm.toLowerCase())) {
              valid = true
              break
            }
          }
        }

        return valid
      })
      return filtered
    },

    getDataTable(searchTerm, from, to, isGetDataToSearch) {
      if (searchTerm === '') {
        this.itemFilterLength = this.itemLength
        this.filteredData = this.filtered(this.rows.slice(+from, +to), searchTerm)
      } else {
        if (isGetDataToSearch) this.dataFilterStore = this.filtered([...this.rows], searchTerm)
        if (!this.dataFilterStore.length) {
          from = from === -1 ? 0 : from
          to = to === 0 ? 20 : to
          this.itemFilterLength = this.itemLength
          this.filteredData = this.filtered(this.rows.slice(+from, +to), '')
        } else {
          this.itemFilterLength = this.dataFilterStore.length
          this.filteredData = this.dataFilterStore.slice(+from, +to)
        }
      }
    },
    // open() {
    //   this.$refs.formulaModal.show();
    // },
    // close() {
    //   this.$refs.formulaModal.hide();
    // },
    resetState() {
      this.selected = null
      this.columns = []
      this.rows = []
      this.maths = []
      this.editing = null
      this.addedColumn = []
      this.error = null
      this.filename = ''
    },
    editColumn(field) {
      if (this.editing === field) return
      let added = this.addedColumn.find((c) => c.field === field)
      if (!added) return
      this.maths = _.cloneDeep(added.maths)
      this.editing = field
      this.rowEditingIndex = this.columns.findIndex((col) => col.field === field)
    },
    editHeader({ field, index, value }) {
      let colIndex = this.columns.findIndex((col) => col.field === field)
      if (colIndex !== -1) {
        let newCol = _.cloneDeep(this.columns[colIndex])
        newCol.label[index] = value.trim()
        this.columns.splice(colIndex, 1, newCol)
      }
    },
    deleteColumn(field, colIdx) {
      this.addedColumn = this.addedColumn.filter((c) => c.field !== field)
      this.columns = this.columns.filter((c) => c.field !== field)
      let rows = _.cloneDeep(this.rows)
      for (let i = 0; i < rows.length; i++) {
        // delete rows[i][field];
        rows[i].splice(colIdx, 1)
      }
      this.rows = rows
      try {
        this.rowEditingIndex = rows[0].length
      } catch {}
      this.updateDatatable++
    },
    transpose(matrix) {
      return matrix[0].map((_, columnIndex) => matrix.map((row) => (row && row[columnIndex] != null ? row[columnIndex] : null)))
    },
    async init() {
      if (!this.datasource) {
        this.columns = []
        this.rows = []
        this.itemFilterLength = 0
        this.itemLength = 0
        this.filteredData = []
        this.dataFilterStore = []
        this.updateDatatable = 0
        return
      }

      if (this.datasource.type === 'timeseries') {
        let datasource = this.$db[this.datasource.id]
        let items = this.datasource.items
        let columns = [{ label: datasource.header, field: 'date' }]
        let rowsOriginal = {}
        let rowsConvert = {}
        rowsOriginal = await this.selectAll(this.datasource.id)
        rowsConvert['dates'] = rowsOriginal['dates']
        for (const key in datasource.columns) {
          let [location, item] = key.split('*')
          rowsConvert[key.replace('*', '-')] = rowsOriginal[key.replace('*', '-')]
          columns.push({ label: datasource.header.length === 3 ? [location, item, datasource.columns[key].unit] : [location, datasource.columns[key].autoGroup, item, datasource.columns[key].unit], field: key, type: 'number' })
        }

        this.columns = columns
        this.rows = this.transpose(Object.values(rowsConvert))

        this.itemLength = this.rows.length
        this.datasourceType = 'timeseries'
      }
    },
    addColumn() {
      setTimeout(() => {
        this.$refs.datatableBody.scrollRight()
      }, 0)

      this.maths = []
      let field = uuidv4()
      let datasource = this.$db[this.datasource.id]
      let placeholder = datasource.header.length === 3 ? ['enter_location', 'enter_item', 'enter_unit'] : ['enter_location', 'enter_group', 'enter_item', 'enter_unit']
      this.columns.push({ label: datasource.header.length === 3 ? ['', '', ''] : ['', '', '', ''], field, type: 'number', success: true, deleteable: true, placeholder })
      this.addedColumn.push({ field, maths: [] })
      let rows = _.cloneDeep(this.rows)
      for (let i = 0; i < rows.length; i++) {
        rows[i].push(0)
      }
      this.rows = rows
      this.updateDatatable++
      this.editing = field
      this.rowEditingIndex = rows[0].length - 1
    },
    clearMath() {
      this.maths = []
    },
    deleteMath() {
      this.maths.pop()
    },
    addMath(type) {
      this.maths.push({ type, id: uuidv4(), value: null })
    },
    changeValue(id, value) {
      let mathIndex = this.maths.findIndex((m) => m.id === id)
      if (mathIndex === -1) return
      this.maths.splice(mathIndex, 1, { ...this.maths[mathIndex], value })
    },
    async equal() {
      if (!this.editing) return

      this.error = null

      let code
      try {
        let expression = this.$refs.maths.map((component) => component.toString()).join('')
        code = compile(expression)
      } catch (error) {
        // this.$store.commit('ecoplot/SET_ERROR', { message: 'Error in expression' })
        this.error = 'Invalid formula'
        return
      }
      // NA value
      const naValue = this.tratNAas0 ? 0 : null
      let stdData = {}
      let norData = {}
      let rows = _.cloneDeep(this.rows)

      let tmpData = this.transpose(rows)
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].field === 'date') continue
        norData[this.columns[i].field] = scaleNormalize(_.cloneDeep(tmpData[i]))
        stdData[this.columns[i].field] = scaleStandardize(_.cloneDeep(tmpData[i]))
      }
      for (let i = 0; i < rows.length; i++) {
        // build scope
        let scope = {}
        this.columns.forEach((col, index) => {
          if (col.field === 'date') return
          let encoded = col.field
            .replaceAll('*', '-')
            .replaceAll('-', '_')
            .split('')
            .map((c) => c.charCodeAt(0))
            .join('')
          let variableName = `_${encoded}_`
          // scope[variableName] = rows[i][index];
          // scope[`STANDARD_${variableName}`] = stdData[col.field][i];
          // scope[`NORMAL_${variableName}`] = norData[col.field][i];
          let valueTemp = rows[i][index]
          let valueStandrad = stdData[col.field][i]
          let valueNormal = norData[col.field][i]
          scope[variableName] = !this.tratNAas0 ? valueTemp : valueTemp ? valueTemp : naValue
          scope[`STANDARD_${variableName}`] = !this.tratNAas0 ? valueStandrad : valueStandrad ? valueStandrad : naValue
          scope[`NORMAL_${variableName}`] = !this.tratNAas0 ? valueNormal : valueNormal ? valueNormal : naValue
        })
        try {
          let value = code.evaluate(scope)
          rows[i][this.rowEditingIndex] = isNaN(value) || value === Infinity || value === -Infinity ? naValue : round(value, 6)
        } catch (error) {
          // khi trong expression có giá trị null => evaluate lỗi
          rows[i][this.rowEditingIndex] = naValue
        }
      }
      this.rows = rows
      this.updateDatatable++
      let addedIndex = this.addedColumn.findIndex((c) => c.field === this.editing)
      this.addedColumn.splice(addedIndex, 1, { field: this.editing, maths: _.cloneDeep(this.maths) })
    },
    async save() {
      this.editing = null
      const GROUP_IDX = this.columns[0].label.findIndex((c) => c === 'GROUP')
      const ITEM_IDX = this.columns[0].label.findIndex((c) => c === 'ITEM')
      const UNIT_IDX = this.columns[0].label.findIndex((c) => c === 'UNIT')
      // validate column name before save
      for (let i = 0; i < this.columns.length; i++) {
        if (!this.columns[i].label[0] || !this.columns[i].label[ITEM_IDX]) {
          this.$toast({ component: ToastificationContent, props: { title: 'Save failed', text: 'Empty column name', icon: 'SlashIcon', variant: 'danger' } })
          return
        }
      }

      // save to current datasource
      // convert array of row object to 1 datasource object and add to store
      const id = uuidv4()
      const name = this.filename + (this.filename.endsWith('.csv') ? '' : '.csv')
      const type = this.datasource.type
      const dates = this.rows.map((r) => r[0])
      let columns = {}
      let autoGroups = {}
      let tmpGroup = [] // temp variable

      this.columns.forEach((column, index) => {
        if (column.field === 'date') return
        let field = column.deleteable ? `${column.label[0]}*${column.label[ITEM_IDX]}` : column.field
        columns[field] = { nullCount: 0, autoGroup: column.label[GROUP_IDX], unit: column.label[UNIT_IDX] }
        tmpGroup = GROUP_IDX !== -1 ? this.getGroupName(column.label[GROUP_IDX]) : []
        tmpGroup.map((grName) => {
          if (!grName) return
          if (!autoGroups[grName]) autoGroups[grName] = []
          autoGroups[grName].push(field)
        })
      })
      const encoding = this.datasource.encoding
      const storage_mode = 'RAM'
      let data = {}
      const keysCol = Object.keys(columns)
      for (let i = 0; i < keysCol.length; i++) {
        for (let j = 0; j < this.rows.length; j++) {
          if (!data[keysCol[i].replace('*', '-')]) data[keysCol[i].replace('*', '-')] = []
          data[keysCol[i].replace('*', '-')].push(this.rows[j][i + 1])
        }
      }
      let itemArray = [...new Set(this.columns.slice(1).map((column) => column.label[ITEM_IDX]))]
      let items = {}
      itemArray.forEach((item) => {
        if (!item) return
        let obj = { min: Infinity, max: -Infinity, unit: '' }
        let groupItem = Object.keys(data)
          .filter((key) => key.endsWith(`-${item}`))
          .reduce((obj, key) => {
            obj[key] = data[key]
            return obj
          }, {})
        obj.min = _.min([].concat(...Object.values(groupItem)))
        obj.max = _.max([].concat(...Object.values(groupItem)))
        // unit
        let index = this.columns.findIndex((column) => column.label[ITEM_IDX] === item)
        obj.unit = this.columns[index].label[UNIT_IDX]
        items[item] = obj
      })
      const locations = this.datasource.locations
      let datasourceObject = { id, name, type, dates, columns, encoding, locations, items, storage_mode, data, csvData: this.rows, header: [...this.columns[0].label], autoGroups }
      await this.storeData(datasourceObject)
      //CREATE OBJ FILE
      const file = await this.createObjectFile(datasourceObject)
      //SAVE TO TEMP FILE
      datasourceObject.file = file
      datasourceObject.size = file.size
      //UPDATE DATASOURCE
      await this.addDatasource(datasourceObject)
      this.updateDatatable++
      let messageTitle = 'create_from_formula'
      let message = this.$t(messageTitle, { move: name })
      this.log.info({ message, id: ACTION_LOG[7].id, messageTitle, move: name })
      // this.log.info({ message: `Create and add datasource '${name}' from datasource tool [Formula]`, id: ACTION_LOG[7].id });
      // this.close();
    },
    getGroupName(str) {
      return str.toString().split('|')
    },
  },
}
</script>

<style>
.tool-custom-radio label {
  line-height: 14px !important;
  padding: 6px 14px !important;
}

.tool-custom-label {
  display: block;
  line-height: 10px;
  margin-bottom: 3px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
}

.formula-modal .calculator {
  width: 370px;
  flex-shrink: 0;
}

.formula-modal .calculation-area {
  position: relative;
  padding: 5px;
  margin-bottom: 2rem;
  line-height: 1;
  min-height: 150px;
  border-radius: 0.357rem;
  border: 1px solid transparent;
}

.formula-modal .calculation-area > span {
  display: inline-block;
  padding: 4px;
  vertical-align: middle;
}

.formula-modal .calculation-area select {
  border: none;
  outline: none;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 0.358rem;
}

.formula-modal .calculation-area .add-operand {
  border: none;
  outline: none;
  width: 22px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 0.358rem;
}

.formula-modal .calculation-area .error-message {
  position: absolute;
  top: 100%;
  left: 0;
  line-height: 1rem;
  font-size: 12px;
}

.operation-area {
  display: flex;
  margin-bottom: 1rem;
  font-size: 16px;
  font-weight: normal;
}

.operation-area button {
  min-width: 50px;
  margin-right: 1rem;
  height: 50px;
  font-size: 16px;
  font-weight: normal;
  padding: 7px;
}

.operation-area button:last-child {
  margin-right: 0;
}

.operation-area .btn-flat-danger {
  background-color: rgba(234, 84, 85, 0.12);
}

.operation-area .btn-flat-success {
  background-color: rgba(40, 199, 111, 0.12);
}

.operation-area .btn-flat-secondary {
  background-color: rgba(130, 134, 139, 0.12);
}

.calculate-button {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.calculate-button button {
  padding: 10px;
  margin: 1rem 0;
  width: 72px;
  font-size: 40px;
}

.dark-layout .formula-modal .calculation-area {
  border-color: #404656;
}
</style>
