<template>
  <div class="h-100 common-datasource-tools">
    <!-- <b-modal size="xl" title="NA Value Tool" centered ok-only :ok-title="$t('save')" :hide-footer="true" ref="naModal" :body-class="'custom-body'" button-size="sm">
    <template #modal-header> -->
    <div class="tools-header">
      <b-form-group class="mr-1 mb-0" style="min-width: 320px">
        <TimeseriesSelect v-model="selected" />
      </b-form-group>
      <b-form-group class="mb-0 mr-1 na-options custom-scrollbar">
        <label class="text-primary tool-custom-label">{{ $t('action') }}</label>
        <b-form-radio-group class="tool-custom-radio" v-model="action" :options="actions" button-variant="outline-primary" size="sm" buttons></b-form-radio-group>
      </b-form-group>
      <b-form-group class="ml-auto mb-0" style="min-width: 88px">
        <b-button variant="success" size="sm" style="padding: 14px 28px" :disabled="action === null || action === 'original'" @click="save">{{ $t('save') }}</b-button>
      </b-form-group>
    </div>
    <!-- <button type="button" class="close" @click="close">&times;</button>
    </template> -->
    <div class="tools-body">
      <DatatableBody :modalType="`NA-Modal`" :action="action" :rowsData="FilterOldData" :filteredIndex="filteredIndex" :columns="columns" :dsType="datasourceType" :originRows="originRowsData" :updateDatatable="updateDatatable" @getData="getDataTable" :itemFilterLength="itemFilterLength" :filtered="filteredData" :itemLength="itemLength" />
      <!-- </b-modal> -->
    </div>
  </div>
</template>

<script>
const _ = require('lodash');
import DatatableBody from '../tools/DatatableBody.vue';
import TimeseriesSelect from './TimeseriesSelect.vue';
import FileMixin from '@/mixins/FileMixin.js';
import { replaceNaWithZero, replaceNaWithForwardValue, replaceNaWithBackwardValue, replaceNaWithLinearInterpolation } from '@/utilities/NumberUtility.js';
import { v4 as uuidv4 } from 'uuid';
const Spline = require('cubic-spline');
const dayjs = require('dayjs');
import { ACTION_LOG } from '@/constants/actionLog';

export default {
  components: { DatatableBody, TimeseriesSelect },
  mounted() {
    this.rows = [];
    this.converted = [];
  },
  mixins: [FileMixin],
  data() {
    return {
      selected: null,
      columns: [],
      action: null,
      itemFilterLength: 0,
      itemLength: 0,
      filteredData: [],
      filteredIndex: [],
      getDataFilter: false,
      dataFilterStore: [],
      originRowsData: [],
      updateDatatable: 0, //update view datatableBody when rows change(add column, ...)
      datasourceType: '',
      FilterOldData: [],
    };
  },
  computed: {
    actions() {
      return [
        { text: this.$t('original'), value: 'original' },
        { text: this.$t('zero'), value: 'na_zero' },
        { text: this.$t('forward'), value: 'na_forward' },
        { text: this.$t('backward'), value: 'na_backward' },
        { text: this.$t('linear_interpolation'), value: 'na_linear_interpolation' },
        { text: this.$t('cubic_spline_interpolation'), value: 'na_cubic_spline' },
        { text: this.$t('remove'), value: 'na_remove' },
      ];
    },
    datasources() {
      return this.$store.state.datasource.datasources;
    },
    options() {
      return this.datasources.filter((d) => d.type === 'timeseries').map((d) => ({ value: d.id, label: d.name }));
    },
    datasource() {
      let datasource = this.datasources.find((d) => d.id === this.selected);
      if (!datasource) return null;
      return { ...datasource, ...this.$db[datasource.id] };
    },
    initProject() {
      return this.$store.state.ecoplot.initProject;
    },
  },
  watch: {
    async datasource(datasource) {
      if (datasource) {
        this.action = 'original';
      } else {
        this.action = null;
      }
      await this.init();
      this.convert();
    },
    action() {
      this.getDataFilter = true;
      this.convert();
    },
    initProject() {
      this.rows = [];
    },
  },
  methods: {
    transpose(matrix) {
      return matrix[0].map((_, columnIndex) => matrix.map((row) => row && row[columnIndex] != null ? row[columnIndex] : null));
    },
    filtered(dataFilter, searchTerm, from) {
      this.filteredIndex = [];
      this.FilterOldData = [];
      let filtered = dataFilter.filter((row, index) => {
        let valid = false;
        if (this.datasourceType !== 'timeseries') {
          for (const key in row) {
            let value = row[key] === null ? 'NA' : row[key].toString();
            if (value.toLowerCase().includes(searchTerm.toLowerCase())) {
              valid = true;
              this.filteredIndex.push(index);
              this.FilterOldData.push(this.rows[index]);
              break;
            }
          }
        } else {
          for (let i = 0; i < row.length; i++) {
            let value = row[i] === null ? 'NA' : row[i].toString();
            if (value.toLowerCase().includes(searchTerm.toLowerCase())) {
              this.filteredIndex.push(index);
              if (from && from >= 0) {
                this.FilterOldData.push(this.rows[index + from]);
              } else {
                this.FilterOldData.push(this.rows[index]);
              }
              valid = true;
              break;
            }
          }
        }

        return valid;
      });
      return filtered;
    },

    getDataTable(searchTerm, from, to, isGetDataToSearch) {
      if (searchTerm === '') {
        this.itemFilterLength = this.itemLength;
        this.filteredData = this.filtered(this.converted.slice(+from, +to), searchTerm, from);
      } else {
        if (isGetDataToSearch || this.getDataFilter) {
          this.dataFilterStore = this.filtered([...this.converted], searchTerm);
        }
        if (!this.dataFilterStore.length) {
          from = from === -1 ? 0 : from;
          to = to === 0 ? 20 : to;
          this.itemFilterLength = this.itemLength;
          this.filteredData = this.filtered(this.rows.slice(+from, +to), '');
        } else {
          this.itemFilterLength = this.dataFilterStore.length;
          this.filteredData = this.dataFilterStore.slice(+from, +to);
        }
        let dataOriginal = [];
        for (let i = 0; i < this.filteredData.length; i++) {
          let index = this.rows.findIndex((row) => row.date === this.filteredData[i].date);
          dataOriginal.push(this.rows[index]);
        }
        this.originRowsData = dataOriginal;
        this.getDataFilter = false;
      }
    },
    // open() {
    //   this.$refs.naModal.show();
    // },
    // close() {
    //   this.$refs.naModal.hide();
    // },
    async init() {
      if (!this.datasource) {
        this.columns = [];
        this.rows = [];
        this.itemFilterLength = 0;
        this.itemLength = 0;
        this.filteredData = [];
        this.dataFilterStore = [];
        this.originRowsData = [];
        this.updateDatatable = 0;
        return;
      }

      if (this.datasource.type === 'timeseries') {
        let items = this.datasource.items;
        let columns = [{ label: this.datasource.header, field: 'date' }];
        let rowsOriginal = {};
        let rowsConvert = {};
        rowsOriginal = await this.selectAll(this.datasource.id);
        rowsConvert['dates'] = rowsOriginal['dates'];
        for (const key in this.$db[this.datasource.id].columns) {
          let [location, item] = key.split('*');
          rowsConvert[key.replace('*', '-')] = rowsOriginal[key.replace('*', '-')];
          columns.push({ label: this.datasource.header.length === 3 ? [location, item, this.datasource.columns[key].unit] : [location, this.datasource.columns[key].autoGroup, item, this.datasource.columns[key].unit], field: key, type: 'number' });
        }
        this.columns = columns;
        this.originalRows = rowsConvert;
        this.rows = this.transpose(Object.values(rowsConvert));
        this.itemLength = this.rows.length;
        this.datasourceType = 'timeseries';
      }
    },
    convert() {
      if (!this.datasource) {
        this.converted = [];
        return;
      }
      let rows = _.cloneDeep(this.rows);
      if (this.action === 'original') {
        this.converted = _.cloneDeep(this.rows);
        this.itemLength = this.converted.length;
        this.updateDatatable++;
        return;
      }

      let converted = [];
      let colsData = this.transpose(rows);

      if (this.action === 'na_zero') {
        rows.forEach((row) => {
          let r = _.cloneDeep(row);
          r = replaceNaWithZero(r);
          converted.push(r);
        });
      }
      if (this.action === 'na_forward') {
        let colsConverted = [];
        colsData.forEach((col) => {
          let c = _.cloneDeep(col);
          c = replaceNaWithForwardValue(c);
          colsConverted.push(c);
        });
        converted = this.transpose(colsConverted);
      }

      if (this.action === 'na_backward') {
        let colsConverted = [];
        colsData.forEach((col) => {
          let c = _.cloneDeep(col);
          c = replaceNaWithBackwardValue(c);
          colsConverted.push(c);
        });
        converted = this.transpose(colsConverted);
      }
      if (this.action === 'na_linear_interpolation') {
        let colsConverted = [];
        colsData.forEach((col) => {
          let c = _.cloneDeep(col);
          c = replaceNaWithLinearInterpolation(c);
          colsConverted.push(c);
        });
        converted = this.transpose(colsConverted);
      }
      if (this.action === 'na_remove') {
        rows.forEach((row) => {
          let r = _.cloneDeep(row);
          if (r.some((dt) => dt === null || dt === '')) return;
          converted.push(r);
        });
      }
      if (this.action === 'na_cubic_spline') {
        const totalValue = 50;
        const countBack = 10;
        let colsConverted = [];

        colsData.forEach((col) => {
          let column = _.cloneDeep(col);
          if (col.some((dt) => dt === null || dt === '')) {
            let arrValue = [];
            let arrNA = [];
            let arrAllValue = [];
            let arrDate = [];
            let arrAllDate = [];
            for (let m = 0; m < col.length; m++) {
              if (col[m] === null || col[m] === '') {
                arrNA.push(m);
              } else {
                arrValue.push(col[m]);
                arrAllValue.push(col[m]);
                arrDate.push(dayjs(colsData[0][m]).unix())
                arrAllDate.push(dayjs(colsData[0][m]).unix())
              }
              if (arrNA.length) {
                if (arrValue.length >= totalValue) {
                  arrValue = arrValue.slice(arrValue.length - totalValue);
                  arrDate = arrDate.slice(arrDate.length - totalValue);
                  const spline = new Spline(arrDate, arrValue);
                  for (let d = 0; d < arrNA.length; d++) {
                    const valueCubic = spline.at(dayjs(colsData[0][arrNA[d]]).unix());
                    column[arrNA[d]] = valueCubic;
                  }
                  arrValue = arrValue.slice(arrValue.length - countBack);
                  arrDate = arrDate.slice(arrDate.length - countBack);
                  arrNA = [];
                }
              }
              if (m == column.length - 1) {
                if (arrNA.length) {
                  if (arrValue.length >= totalValue) {
                    arrValue = arrValue.slice(arrValue.length - totalValue)
                    arrDate.slice(arrDate.length - totalValue)
                  }
                  else {
                    if (arrAllValue.length >= totalValue) {
                      arrValue = arrAllValue.slice(arrAllValue.length - totalValue)
                      arrDate = arrAllDate.slice(arrAllDate.length - totalValue)
                    }
                    else {
                      arrValue = arrAllValue;
                      arrDate = arrAllDate
                    }
                  }
                  const splineLast = new Spline(arrDate, arrValue);
                  for (let d = 0; d < arrNA.length; d++) {
                    const valueCubicLast = splineLast.at(dayjs(colsData[0][arrNA[d]]).unix());
                    column[arrNA[d]] = valueCubicLast;
                  }
                }
              }
            }
            colsConverted.push(column);
          }
          else{
            colsConverted.push(column);
          }
        })
        converted = this.transpose(colsConverted);
      }
      this.converted = converted;
      this.itemLength = this.converted.length;
      this.updateDatatable++;
    },
    async save() {
      // convert array of row object to 1 datasource object and add to store
      const id = uuidv4();
      const name = `${this.datasource.name.slice(0, -4)}_${this.action}.csv`;
      const type = this.datasource.type;
      const dates = this.converted.map((r) => r[0]);
      const columns = this.datasource.columns;
      const encoding = this.datasource.encoding;
      const storage_mode = 'RAM';
      const locations = this.datasource.locations;
      const autoGroups = this.datasource.autoGroups;
      const header = this.datasource.header;
      const ITEM_IDX = this.columns[0].label.findIndex((c) => c === 'ITEM');
      const UNIT_IDX = this.columns[0].label.findIndex((c) => c === 'UNIT');
      let data = {};
      const keysCol = Object.keys(columns);
      for (let i = 0; i < keysCol.length; i++) {
        for (let j = 0; j < this.converted.length; j++) {
          if (!data[keysCol[i].replace('*', '-')]) data[keysCol[i].replace('*', '-')] = [];
          data[keysCol[i].replace('*', '-')].push(this.converted[j][i + 1]);
        }
      }

      // Build Items
      let items = {};
      let itemArray = [...new Set(this.columns.slice(1).map((column) => column.label[ITEM_IDX]))];
      itemArray.forEach((item) => {
        if (!item) return;
        let obj = { min: Infinity, max: -Infinity, unit: '' };
        // Min & max
        for (const key in data) {
          if (key.endsWith(`-${item}`)) {
            for (let i = 0; i < data[key].length; i++) {
              if (data[key][i] !== null && data[key][i] < obj.min) obj.min = data[key][i];
              if (data[key][i] !== null && data[key][i] > obj.max) obj.max = data[key][i];
            }
          }
        }
        // Unit
        let index = this.columns.findIndex((column) => column.label[ITEM_IDX] === item);
        obj.unit = this.columns[index].label[UNIT_IDX];
        items[item] = obj;
      });
      let datasourceObject = { id, name, type, dates, columns, storage_mode, encoding, locations, items, data, csvData: this.converted, autoGroups, header };
      await this.storeData(datasourceObject);
      //CREATE OBJ FILE
      const file = await this.createObjectFile(datasourceObject);
      datasourceObject.file = file;
      datasourceObject.size = file.size;
      //UPDATE DATASOURCE
      await this.addDatasource(datasourceObject);
      this.updateDatatable++;
      let messageTitle = 'create_from_na_value';
      let message = this.$t(messageTitle, { move: name });
      this.log.info({ message, id: ACTION_LOG[7].id, messageTitle, move: name });
      // this.log.info({ message: `Create and add datasource '${name}' from datasource tool [NA Value]`, id: ACTION_LOG[7].id });
      // this.close();
    },
  },
};
</script>
<style>
.tool-custom-radio label {
  line-height: 14px !important;
  padding: 6px 14px !important;
}

.tool-custom-label {
  display: block;
  line-height: 10px;
  margin-bottom: 3px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
}
.modal-xl {
  max-width: 1220px !important;
}

.na-options {
  max-width: 100%;
  overflow: auto;
}
</style>
