<template>
  <div class="h-100 common-datasource-tools">
    <!-- <b-modal size="xl" title="Frequency Tool" centered ok-only :ok-title="$t('save')" :hide-footer="true" ref="frequencyModal" :body-class="'custom-body'" button-size="sm" @show="resetState">
    <template #modal-header> -->
    <div class="tools-header">
      <b-form-group class="mr-1 mb-0">
        <TimeseriesSelect v-model="selected" />
      </b-form-group>
      <b-form-group class="mr-1 mb-0">
        <label class="text-primary tool-custom-label">{{ $t('frequency') }}</label>
        <b-form-radio-group class="tool-custom-radio" v-model="frequency" :options="frequencies" button-variant="outline-primary" size="sm" buttons></b-form-radio-group>
      </b-form-group>
      <b-form-group class="mb-0">
        <label class="text-primary tool-custom-label">{{ $t('method') }}</label>
        <b-form-radio-group class="tool-custom-radio" v-model="method" :options="methods" button-variant="outline-primary" size="sm" buttons :disabled="frequency === null || frequency === 'original'"></b-form-radio-group>
      </b-form-group>
      <b-form-group class="ml-auto mb-0">
        <b-button variant="success" size="sm" style="padding: 14px 28px" :disabled="frequency === null || frequency === 'original'" @click="save">{{ $t('save') }}</b-button>
      </b-form-group>
    </div>
    <!-- <button type="button" class="close" @click="close">&times;</button> -->
    <!-- </template> -->
    <div class="tools-body">
      <DatatableBody :modalType="null" :action="null" :rowsData="null" :filteredIndex="null" :columns="columns" :dsType="datasourceType" :updateDatatable="updateDatatable" @getData="getDataTable" :itemFilterLength="itemFilterLength" :filtered="filteredData" :itemLength="itemLength" />
    </div>
    <!-- </b-modal> -->
  </div>
</template>

<script>
const _ = require('lodash');
const dayjs = require('dayjs');
import { v4 as uuidv4 } from 'uuid';
import DatatableBody from '../tools/DatatableBody.vue';
import TimeseriesSelect from './TimeseriesSelect.vue';
import FileMixin from '@/mixins/FileMixin.js';
import { ACTION_LOG } from '@/constants/actionLog';
export default {
  components: { DatatableBody, TimeseriesSelect },
  mounted() {
    this.rows = [];
    this.converted = [];
  },
  mixins: [FileMixin],
  data() {
    return {
      selected: null,
      columns: [],
      frequency: 'original',
      method: 'sum',
      frequencies: [{ text: this.$t('original'), value: 'original' }],

      itemFilterLength: 0,
      itemLength: 0,
      filteredData: [],
      dataFilterStore: [],
      updateDatatable: 0,
      datasourceType: '',
    };
  },
  computed: {
    methods() {
      return [
        { text: this.$t('sum'), value: 'sum' },
        { text: this.$t('mean'), value: 'mean' },
      ];
    },
    datasources() {
      return this.$store.state.datasource.datasources;
    },
    options() {
      return this.datasources.filter((d) => d.type === 'timeseries').map((d) => ({ value: d.id, label: d.name }));
    },
    datasource() {
      let datasource = this.datasources.find((d) => d.id === this.selected);
      if (!datasource) return null;
      return { ...datasource, ...this.$db[datasource.id] };
    },
    datasourceTools() {
      return this.$store.state.map.datasourceTools;
    },
  },
  watch: {
    async datasource() {
      await this.init();
      this.convert();
      this.updateDatatable++;
    },
    frequency() {
      this.convert();
      this.updateDatatable++;
    },
    method() {
      this.convert();
      this.updateDatatable++;
    },
    datasourceTools() {
      this.resetState();
    },
    columns:{
      deep:true,
      handler(){
      }
    },
    
  },
  methods: {
    resetState() {
      this.selected = null;
      this.rows = [];
    },
    filtered(dataFilter, searchTerm) {
      let filtered = dataFilter.filter((row) => {
        let valid = false;
        if (this.datasourceType !== 'timeseries') {
          for (const key in row) {
            let value = row[key] === null ? 'NA' : row[key].toString();
            if (value.toLowerCase().includes(searchTerm.toLowerCase())) {
              valid = true;
              break;
            }
          }
        } else {
          for (let i = 0; i < row.length; i++) {
            let value = row[i] === null ? 'NA' : row[i].toString();
            if (value.toLowerCase().includes(searchTerm.toLowerCase())) {
              valid = true;
              break;
            }
          }
        }

        return valid;
      });
      return filtered;
    },

    getDataTable(searchTerm, from, to, isGetDataToSearch) {
      if (searchTerm === '') {
        from = from === -1 ? 0 : from;
        to = to === 0 ? 20 : to;
        this.itemFilterLength = this.itemLength;
        this.filteredData = this.filtered(this.converted.slice(+from, +to), searchTerm);
      } else {
        if (isGetDataToSearch) this.dataFilterStore = this.filtered([...this.rows], searchTerm);
        if (!this.dataFilterStore.length) {
          from = from === -1 ? 0 : from;
          to = to === 0 ? 20 : to;
          this.itemFilterLength = this.itemLength;
          this.filteredData = this.filtered(this.rows.slice(+from, +to), '');
        } else {
          this.itemFilterLength = this.dataFilterStore.length;
          this.filteredData = this.dataFilterStore.slice(+from, +to);
        }
      }
    },
    // open() {
    //   this.$refs.frequencyModal.show();
    // },
    // close() {
    //   this.$refs.frequencyModal.hide();
    // },
    transpose(matrix) {
      return matrix[0].map((_, columnIndex) => matrix.map((row) => row && row[columnIndex] != null ? row[columnIndex] : null));
    },
    async init() {
      if (!this.datasource) {
        this.columns = [];
        this.rows = [];
        this.itemFilterLength = 0;
        this.itemLength = 0;
        this.filteredData = [];
        this.dataFilterStore = [];
        this.updateDatatable = 0;
        this.frequencies = [{ text: this.$t('original'), value: 'original' }];
        this.frequency = 'original';
        return;
      }

      if (this.datasource.type === 'timeseries') {
        let items = this.datasource.items;
        let columns = [{ label: this.datasource.header, field: 'date' }];
        let rowsOriginal = {};
        let rowsConvert = {};
        rowsOriginal = await this.selectAll(this.datasource.id);
        rowsConvert['dates'] = rowsOriginal['dates'];
        for (const key in this.$db[this.datasource.id].columns) {
          let [location, item] = key.split('*');
          rowsConvert[key.replace('*', '-')] = rowsOriginal[key.replace('*', '-')];
          columns.push({ label: this.datasource.header.length === 3 ? [location, item, this.datasource.columns[key].unit] : [location, this.datasource.columns[key].autoGroup, item, this.datasource.columns[key].unit], field: key, type: 'number' });
        }
        this.columns = columns;
        this.rows = this.transpose(Object.values(rowsConvert));
        this.itemLength = this.rows.length;
        this.datasourceType = 'timeseries';
      }
      let frequencies = [];
      if (this.datasource.type === 'timeseries') {
        let dates = this.datasource.dates;
        let diff = dayjs(dates[1]).diff(dayjs(dates[0]), 'second');
        if (diff < 365 * 24 * 60 * 60) frequencies.unshift({ text: this.$t('yearly'), value: 'yearly' });
        if (diff < 28 * 24 * 60 * 60) frequencies.unshift({ text: this.$t('monthly'), value: 'monthly' });
        if (diff < 24 * 60 * 60) frequencies.unshift({ text: this.$t('daily'), value: 'daily' });
        if (diff < 60 * 60) frequencies.unshift({ text: this.$t('hour'), value: 'hour' });
        if (diff < 60) frequencies.unshift({ text: this.$t('minute'), value: 'minute' });
      }
      this.frequencies = [{ text: this.$t('original'), value: 'original' }, ...frequencies];
      this.frequency = 'original';
    },
    convert() {
      if (!this.datasource) {
        this.converted = [];
        return;
      }

      // original mode => return original data
      if (this.frequency === 'original') {
        this.converted = _.cloneDeep(this.rows);
        this.itemLength = this.converted.length;
        return;
      }

      let converted = [];

      // group by frequency
      let groups = _.groupBy(this.rows, (row) => {
        if (this.frequency === 'yearly') return row[0].substring(0, 4);
        if (this.frequency === 'monthly') return row[0].substring(0, 7);
        if (this.frequency === 'daily') return row[0].substring(0, 10);
        if (this.frequency === 'hour') return row[0].substring(0, 13);
        if (this.frequency === 'minute') return row[0].substring(0, 16);
        return row[0];
      });

      // get columns need calculate sum|mean
      for (const key in groups) {
        let row = [];
        row.push(dayjs(key).format('YYYY-MM-DD HH:mm:ss'));
        let colsData = this.transpose(groups[key]);
        for (let i = 1; i < colsData.length; i++) {
          let value = [];
          if (this.method == 'sum') {
            value = Math.round(_.sum(colsData[i]) * 1e6) / 1e6;
          }
          if (this.method == 'mean') {
            let colsDataNotNull = colsData[i].filter((value) => !_.isNil(value) && !isNaN(value) && this.isNumericString(value));
            value = colsDataNotNull.length ? Math.round(_.mean(colsDataNotNull) * 1e6) / 1e6 : 'NA';
          }
          row.push(value);
        }
        converted.push(row);
      }
      this.converted = converted;
      this.itemLength = this.converted.length;
    },
    isNumericString(str) {
      try {
        const num = _.toNumber(str);
        return !_.isNaN(num) && _.isFinite(num);
      } catch {}
      return false;
    },
    async save() {
      // convert array of row object to 1 datasource object and add to store
      const id = uuidv4();
      const name = `${this.datasource.name.slice(0, -4)}_${this.frequency}_${this.method}.csv`;
      const type = this.datasource.type;
      const dates = this.converted.map((r) => r[0]);
      const columns = this.datasource.columns;
      const encoding = this.datasource.encoding;
      const storage_mode = 'RAM';
      const autoGroups = this.datasource.autoGroups;
      const header = this.datasource.header;
      const ITEM_IDX = this.columns[0].label.findIndex((c) => c === 'ITEM');
      const UNIT_IDX = this.columns[0].label.findIndex((c) => c === 'UNIT');
      const locations = this.datasource.locations;
      let data = {};
      const keysCol = Object.keys(columns);
      for (let i = 0; i < keysCol.length; i++) {
        for (let j = 0; j < this.converted.length; j++) {
          if (!data[keysCol[i].replace('*', '-')]) data[keysCol[i].replace('*', '-')] = [];
          data[keysCol[i].replace('*', '-')].push(this.converted[j][i + 1]);
        }
      }

      // Build Items
      let items = {};
      let itemArray = [...new Set(this.columns.slice(1).map((column) => column.label[ITEM_IDX]))];
      itemArray.forEach((item) => {
        if (!item) return;
        let obj = { min: Infinity, max: -Infinity, unit: '' };
        // Min & max
        for (const key in data) {
          if (key.endsWith(`-${item}`)) {
            for (let i = 0; i < data[key].length; i++) {
              if (data[key][i] !== null && data[key][i] < obj.min) obj.min = data[key][i];
              if (data[key][i] !== null && data[key][i] > obj.max) obj.max = data[key][i];
            }
          }
        }
        // Unit
        let index = this.columns.findIndex((column) => column.label[ITEM_IDX] === item);
        obj.unit = this.columns[index].label[UNIT_IDX];
        items[item] = obj;
      });
      let datasourceObject = { id, name, type, columns, encoding, dates, storage_mode, locations, items, data, csvData: this.converted, autoGroups, header };
      await this.storeData(datasourceObject);
      const file = await this.createObjectFile(datasourceObject);
      datasourceObject.file = file;
      datasourceObject.size = file.size;
      await this.addDatasource(datasourceObject);
      this.updateDatatable++;
      let messageTitle = 'create_from_resample';
      let message = this.$t(messageTitle, { move: name });
      this.log.info({ message, id: ACTION_LOG[7].id, messageTitle, move: name });
      // this.log.info({ message: `Create and add datasource '${name}' from datasource tool [Resample]`, id: ACTION_LOG[7].id });
    },
  },
};
</script>

<style>
.tool-custom-radio label {
  line-height: 14px !important;
  padding: 6px 14px !important;
}

.tool-custom-label {
  display: block;
  line-height: 10px;
  margin-bottom: 3px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
}
.common-datasource-tools .table-container {
  height: calc(100% - 95px) !important;
}

.dark-layout .common-datasource-tools {
  background-color: #283046 !important;
}
.common-datasource-tools {
  background-color: #fff;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 0.357rem;
}
.common-datasource-tools .tools-header {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 1.4rem;
  width: 100%;
}

.common-datasource-tools .tools-body {
  padding: 0.8rem 1.4rem;
  height: calc(100% - 65px);
}
</style>
