<template>
  <div class="datasource-tools custom-scrollbar card mb-0">
    <b-row class="h-100">
      <b-col class="h-100 pr-0 menu-tools">
        <div class="tools-modal custom-scrollbar">
          <div class="ds-tool-item" @click="setDatasourceToolsActive('frequency')" :class="{ active: datasourceTools === 'frequency' }">
            <img class="tool-icon" :src="require(`/static/images/tools/frequency-tool.png`)" alt="" />
            <div class="text-left">
              <h6 class="tool-name">{{ $t('frequency') }}</h6>
              <span class="tool-description" data-lang="master_the_basics">{{ $t('frequency_description') }}</span>
            </div>
          </div>
          <div class="ds-tool-item" @click="setDatasourceToolsActive('merge')" :class="{ active: datasourceTools === 'merge' }">
            <img class="tool-icon" :src="require(`/static/images/tools/merge-csv-tool.png`)" alt="" />
            <div class="text-left">
              <h6 class="tool-name">{{ $t('merges') }}</h6>
              <span class="tool-description" data-lang="master_the_basics">{{ $t('merges_description') }}</span>
            </div>
          </div>
          <div class="ds-tool-item" @click="setDatasourceToolsActive('stationarity')" :class="{ active: datasourceTools === 'stationarity' }">
            <img class="tool-icon" :src="require(`/static/images/tools/stationarity-tool.png`)" alt="" />
            <div class="text-left">
              <h6 class="tool-name">{{ $t('stationarity') }}</h6>
              <span class="tool-description" data-lang="master_the_basics">{{ $t('stationarity_description') }}</span>
            </div>
          </div>

          <div class="ds-tool-item" @click="setDatasourceToolsActive('na')" :class="{ active: datasourceTools === 'na' }">
            <img class="tool-icon" :src="require(`/static/images/tools/na-value-tool.png`)" alt="" />
            <div class="text-left">
              <h6 class="tool-name">{{ $t('na_value_tool') }}</h6>
              <span class="tool-description" data-lang="master_the_basics">{{ $t('na_value_description') }}</span>
            </div>
          </div>
          <div class="ds-tool-item" @click="setDatasourceToolsActive('formula')" :class="{ active: datasourceTools === 'formula' }">
            <img class="tool-icon" :src="require(`/static/images/tools/formula-tool.png`)" alt="" />
            <div class="text-left">
              <h6 class="tool-name">{{ $t('formula') }}</h6>
              <span class="tool-description" data-lang="master_the_basics">{{ $t('formula_description') }}</span>
            </div>
          </div>
          <div class="ds-tool-item" @click="setDatasourceToolsActive('unitConverter')" :class="{ active: datasourceTools === 'unitConverter' }">
            <img class="tool-icon" :src="require(`/static/images/tools/pencil-and-ruler.png`)" alt="" />
            <div class="text-left">
              <h6 class="tool-name">{{ $t('unit_converter') }}</h6>
              <span class="tool-description" data-lang="master_the_basics">{{ $t('unit_converter_description') }}</span>
            </div>
          </div>
          <div class="ds-tool-item" @click="setDatasourceToolsActive('cumulative')" :class="{ active: datasourceTools === 'cumulative' }">
            <div class="tool-icon">
              <dynamic-icon :icon="`tools/cumulative`" />
            </div>
            <div class="text-left">
              <h6 class="tool-name">{{ $t('cumulative') }}</h6>
              <span class="tool-description" data-lang="master_the_basics">{{ $t('cumulative_description') }}</span>
            </div>
          </div>
          <div class="ds-tool-item" @click="setDatasourceToolsActive('outlier')" :class="{ active: datasourceTools === 'outlier' }">
            <div class="tool-icon">
              <dynamic-icon :icon="`tools/outlier`" />
            </div>
            <div class="text-left">
              <h6 class="tool-name">{{ $t('outlier_removal') }}</h6>
              <span class="tool-description" data-lang="master_the_basics"> {{ $t('outlier_removal_description') }}</span>
            </div>
          </div>
        </div>
      </b-col>
      <b-col class="h-100 table-tools">
        <div :class="`tools-content h-100 ${datasourceTools}`">
          <FrequencyModal v-if="datasourceTools === 'frequency'" />
          <MergeModal v-if="datasourceTools === 'merge'" />
          <NaModal v-if="datasourceTools === 'na'" />
          <StationarityModal v-if="datasourceTools === 'stationarity'" />
          <FormulaModal v-if="datasourceTools === 'formula'" />
          <UnitConverterModal v-if="datasourceTools === 'unitConverter'" />
          <CumulativeModal v-if="datasourceTools === 'cumulative'" />
          <OutlierModal v-if="datasourceTools === 'outlier'" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FrequencyModal from '../map/control-panel/datasources/modal/FrequencyModal.vue'
import MergeModal from '../map/control-panel/datasources/modal/MergeModal.vue'
import NaModal from '../map/control-panel/datasources/modal/NaModal.vue'
import StationarityModal from '../map/control-panel/datasources/modal/StationarityModal.vue'
import FormulaModal from '../map/control-panel/datasources/modal/FormulaModal.vue'
import UnitConverterModal from '../map/control-panel/datasources/modal/UnitConverterModal.vue'
import CumulativeModal from '../map/control-panel/datasources/modal/CumulativeModal.vue'
import OutlierModal from '../map/control-panel/datasources/modal/OutlierModal.vue'

export default {
  components: { FrequencyModal, MergeModal, NaModal, StationarityModal, FormulaModal, UnitConverterModal, CumulativeModal, OutlierModal },
  computed: {
    datasourceTools() {
      return this.$store.state.map.datasourceTools
    },
  },
  methods: {
    setDatasourceToolsActive(toolsName) {
      this.$store.commit('map/SET_DATASOURCE_TOOLS_ACTIVE', toolsName)
    },
  },
}
</script>

<style scoped>
@media (max-width: 1000px) {
  .datasource-tools .menu-tools {
    flex: unset !important;
    max-width: 100% !important;
    margin-bottom: 1rem;
  }
  .datasource-tools .table-tools {
    flex: unset !important;
    max-width: 100% !important;
  }
}
.datasource-tools .menu-tools {
  flex: 0 0 25%;
  max-width: 25%;
}
.datasource-tools .table-tools {
  flex: 0 0 75%;
  max-width: 75%;
}
.dark-layout .datasource-tools .tools-modal .active {
  background-color: rgba(22, 29, 49, 0.7);
}
.datasource-tools .tools-modal .active {
  border-left: 5px solid #28c76f;
  box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.15);
}
.tools-modal {
  height: 100%;
  overflow-y: auto;
  border-radius: 0.357rem;
  background-color: #fff;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}
.dark-layout .datasource-header {
  background-color: #161d31;
}
.datasource-body {
  height: 100%;
  overflow-y: auto;
  align-items: center;
  position: relative;
}
.datasource-header {
  background-color: #f8f8f8;
  padding: 0.8rem 1.4rem;
  border-radius: 0.357rem;
  margin-bottom: 15px;
}
.datasource-tools {
  height: calc(100vh - 30px - 90.3px + 15px);
  background-color: #f8f8f8;
  box-shadow: unset;
  overflow-y: auto;
  overflow-x: hidden;
}
.l-container {
  max-height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.tools-modal .tool-icon {
  width: 60px;
  height: 60px;
  margin-right: 15px;
}

.tools-modal .tool-name {
  font-size: 20px;
  line-height: 1;
  color: #5e5873;
  font-weight: 500;
}
</style>

<style lang="scss">
// Settings

$c_0: #000000;
$c_1: #353540;

.l-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.b-game-card {
  position: relative;
  z-index: 1;
  width: 100%;
  padding-bottom: 100%;
  perspective: 1000px;

  &__cover {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    perspective-origin: 50% 50%;
    transform-style: preserve-3d;
    transform-origin: top center;
    will-change: transform;
    transform: skewX(0.001deg);
    transition: transform 0.35s ease-in-out;
    border: 1px solid #f8f8f8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    // Gloss

    &::after {
      display: block;
      opacity: 0;
      content: '';
      position: absolute;
      z-index: 100;
      top: 0;
      left: 0;
      width: 100%;
      height: 120%;
      background: linear-gradient(226deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 35%, rgba(255, 255, 255, 0.2) 42%, rgba(255, 255, 255, 0) 60%);
      transform: translateY(-20%);
      will-change: transform;
      transition: transform 0.65s cubic-bezier(0.18, 0.9, 0.58, 1), opacity 0.65s cubic-bezier(0.18, 0.9, 0.58, 1);
    }
  }

  &:hover &__cover {
    transform: rotateX(7deg) translateY(-6px);

    &::after {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  // Shadows

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    background: rgba($c_0, 0.5);
    box-shadow: 0 6px 12px 12px rgba($c_0, 0.4);
    will-change: opacity;
    transform-origin: top center;
    transform: skewX(0.001deg);
    transition: transform 0.35s ease-in-out, opacity 0.5s ease-in-out;
  }

  &:hover::before {
    opacity: 0.6;
    transform: rotateX(7deg) translateY(-6px) scale(1.05);
  }
}

.dark-layout {
  .b-game-card {
    &__cover {
      border: 1px solid #161d31;
    }
  }
}
// .tools-modal .tool-description {
//   display: flex;
//   text-align: left;
// }
.tools-modal .tools_description,
.tools-modal .tool-description {
  color: #5e5873;
  font-size: 13px;
}
.dark-layout .tools-modal .tools_description,
.dark-layout .tools-modal .tool-description {
  color: #939bb3;
}

.tools-modal .ds-tool-item {
  border-radius: 0.357rem;
  color: #5e5873;
  padding: 15px 15px 15px 10px;
  text-align: center;
  cursor: pointer;
  transition: box-shadow 0.5s;
  display: flex;
  justify-content: left;
  align-items: center;
  border-left: 5px solid transparent;
}
.dark-layout .tools-modal .ds-tool-item {
  backdrop-filter: blur(0px);
}
.tools-modal .ds-tool-item:hover {
  box-shadow: 0 3px 6px 1px rgb(0 0 0 / 15%);
}
.dark-layout .tools-modal .ds-tool-item:hover {
  background-color: rgba(22, 29, 49, 0.7);
  box-shadow: 0 3px 6px 1px rgb(0 0 0 / 15%);
}

.dark-layout .tools-modal .tool-name {
  color: #fff;
}

.dark-layout .datasource-tools {
  background-color: #161d31;
}
.dark-layout .tools-modal {
  background-color: #283046;
}
.tools-content.na {
  button.datasource-select-container {
    width: inherit !important;
  }
}
</style>
