<template>
  <b-card no-body class="timeseries-chart-card">
    <b-card-body>
      <div class="timeseries-chart" ref="chart" :style="{ visibility: showChart ? 'visible' : 'hidden', opacity: showChart ? 1 : 0 }"></div>
      <div class="timeseries-chart empty-chart" :style="{ visibility: showChart ? 'hidden' : 'visible' }">
        <EmptyChart :description="$t('tips_timeseries')" />
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
const _ = require('lodash');
import Plotly from 'plotly.js-dist';
import DownloadMixin from '@/mixins/DownloadMixin';
import EmptyChart from '../../../../../common/EmptyChart.vue';
import i18n from '@/libs/i18n';
import { CustomLayout } from '@/mixins/ThemeMixin.js';
import { locale } from '@/scripts/plotly/locale.js';

const LAYOUT = {
  hovermode: 'closest',
  yaxis: { automargin: true },
  xaxis: { automargin: true },
};

const CONFIG = {
  displaylogo: false,
  responsive: true,
  editable: true,
  modeBarButtonsToRemove: ['toImage'],
};

export default {
  props: ['chart', 'minValue', 'maxValue', 'datesSelect','chartFontOutlier'],
  mixins: [DownloadMixin, CustomLayout],
  components: { EmptyChart, locale },
  data() {
    return {
      localePlotly: 'en',
      annotations: undefined,
    };
  },
  mounted() {
    'undefined' == typeof Plotly ? ((window.PlotlyLocales = window.PlotlyLocales || []), window.PlotlyLocales.push(locale)) : Plotly.register(locale);
    this.updateChart();
    // listen to div resize => chart resize
    new ResizeObserver(() => {
      if (document.getElementsByClassName('timeseries-chart')[0] && this.$refs.chart && this.$refs.chart.style.display !== 'none') {
        Plotly.Plots.resize(this.$refs.chart);
      }
    }).observe(this.$refs.chart);
  },
  computed: {
    showChart() {
      return (this.chart.data || []).length;
    },
    locale() {
      return i18n.locale;
    },
    name() {
      return this.$options.name;
    },
  },
  watch: {
    chart: {
      deep: false,
      handler() {
        this.updateChart(true);
      },
    },
    locale() {
      if (this.chart.data.length > 0) {
        this.updateChart();
      }
    },
    chartFontOutlier: {
      deep: true,
      handler() {
        if (this.chartFontOutlier) {
          this.updateChartLayout();
        }
      },
    },
  },
  methods: {
    updateChart(isUpdate = false) {
      let _config = CONFIG;
      _config.locale = i18n.locale;
      let layoutTemp = {};
      try {
        if (this.chartFontOutlier) {
          layoutTemp = _.merge(layoutTemp, { font: this.chartFontOutlier });
        }
      } catch {}
      if (isUpdate) {
        let layout = _.merge({}, this.themeLayout, LAYOUT, this.chart.layout,layoutTemp);
        if ((this.$refs.chart && this.$refs.chart.data && this.$refs.chart.data.length && this.minValue) || (this.$refs.chart && this.$refs.chart.data && this.$refs.chart.data.length && this.maxValue)) {
          layout.shapes=[
            { type: 'line', xref: 'x', yref: 'y', x0: this.datesSelect[0], y0: Number(this.minValue), x1: this.datesSelect[this.datesSelect.length - 1], y1: Number(this.minValue), line: { color: '#FF9F44', width: 1, dash: 'dashdot' } },
            { type: 'line', xref: 'x', yref: 'y', x0: this.datesSelect[0], y0: Number(this.maxValue), x1: this.datesSelect[this.datesSelect.length - 1], y1: Number(this.maxValue), line: { color: '#ea5455', width: 1, dash: 'dashdot' } },
          ]
        }
        Plotly.react(this.$refs.chart, this.chart.data, layout, _config)
        // .then(() => {
        //   this.updatePlotTimeIndex();
        // });
      } else {
        let layout = _.merge({}, this.themeLayout, LAYOUT, this.chart.layout,layoutTemp);
        Plotly.newPlot(this.$refs.chart, this.chart.data, layout, _config);
      }
    },
    updatePlotTimeIndex() {
      if ((this.$refs.chart && this.$refs.chart.data && this.$refs.chart.data.length && this.minValue) || (this.$refs.chart && this.$refs.chart.data && this.$refs.chart.data.length && this.maxValue)) {
        Plotly.relayout(this.$refs.chart, {
          shapes: [
            { type: 'line', xref: 'x', yref: 'y', x0: this.datesSelect[0], y0: Number(this.minValue), x1: this.datesSelect[this.datesSelect.length - 1], y1: Number(this.minValue), line: { color: '#FF9F44', width: 1, dash: 'dashdot' } },
            { type: 'line', xref: 'x', yref: 'y', x0: this.datesSelect[0], y0: Number(this.maxValue), x1: this.datesSelect[this.datesSelect.length - 1], y1: Number(this.maxValue), line: { color: '#ea5455', width: 1, dash: 'dashdot' } },
          ],
        });
      }
    },
    updateChartLayout() {
        try {
          let layout = this.$refs.chart.layout;
          layout = _.merge(layout, { font: this.chartFontOutlier });
          Plotly.relayout(this.$refs.chart, layout);
        } catch {}
    },
  },
};
</script>

<style scoped>
.timeseries-chart-card .card-body {
  padding-top: 0;
}

.timeseries-chart.empty-chart {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.timeseries-chart {
  height: calc(100vh - 30px - 90.3px - 90px - 2rem - 36px);
}
</style>
<style>
.plot-container.plotly .user-select-none.svg-container {
  z-index: 1 !important;
}
</style>
