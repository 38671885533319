<template>
  <b-button variant="flat-primary" class="datasource-select-container" ref="button">
    <div class="datasource-pointer" v-ripple.400="'rgba(57, 123, 255, 0.15)'" :style="{ width: width + 'px' }">
      <b-media no-body class="align-items-center datasource-media">
        <b-media-aside style="margin-right: 10px">
          <b-avatar rounded size="42" variant="light-primary">
            <feather-icon size="18" icon="DatabaseIcon" style="color: #397bff" />
          </b-avatar>
        </b-media-aside>
        <b-media-body style="text-align: left">
          <p class="mb-0">{{ datasource ? datasource.name : this.$t('select_datasource') }}</p>
        </b-media-body>
      </b-media>
      <b-badge pill variant="light-primary" style="margin: 0 10px">
        <feather-icon size="18" icon="ChevronDownIcon" />
      </b-badge>
    </div>
    <div class="datasource-select bg-light" :style="{ width: width + 'px' }">
      <table class="table">
        <thead>
          <tr>
            <th :style="{ width: width - 20 + 'px' }">
              <div class="d-flex justify-content-between align-items-center" style="padding-right: 5px">
                <span style="line-height: 22px">{{ $t('datasource') }}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="custom-scrollbar">
                <div class="datasource-option" v-for="datasource in datasources" :key="datasource.id" @click="selectDatasource(datasource.id)">
                  <div class="datasource-content">
                    <div class="datasource-name" :title="datasource.name">{{ datasource.name }}</div>
                    <small class="count-label">{{ recordCount(datasource.id) + ' ' + $t('records')}} </small>
                  </div>
                  <b-form-radio :checked="datasourceSelected === datasource.id ? 'B' : ''" value="B" disabled></b-form-radio>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-button>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    width: {
      type: Number,
      default: 250,
    },
    closeAfterSelect: {
      type: Boolean,
      default: false,
    },
    blackList: {
      type: Array,
      default: () => [],
    },
  },
  directives: { Ripple },
  computed: {
    datasourceSelected: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
        if (this.closeAfterSelect && value) {
          this.$refs.button.blur()
        }
      },
    },
    datasources() {
      return this.$store.state.datasource.datasources.filter((d) => d.type === 'timeseries').filter((d) => !this.blackList.includes(d.id))
    },
    datasource() {
      if (!this.datasourceSelected) return null
      let datasource = this.datasources.find((d) => d.id === this.datasourceSelected)
      if (!datasource) return null
      return { ...datasource, ...this.$db[datasource.id] }
    },
  },
  watch: {
    datasources(datasources) {
      if (!datasources.some((d) => d.id === this.datasourceSelected)) {
        this.datasourceSelected = null
      }
    },
  },
  methods: {
    recordCount(id) {
      return this.$db[id].dates.length
    },
    selectDatasource(id) {
      if (this.datasourceSelected === id) {
        this.datasourceSelected = null
      } else {
        this.datasourceSelected = id
      }
    },
  },
}
</script>

<style scoped>
.datasource-select-container {
  padding: 0;
  position: relative;
  width: 100%;
  border: none;
  outline: none;
  cursor: default !important;
  background-color: rgba(57, 123, 255, 0.12) !important;
  border-radius: 0.357rem;
  color: #5e5873;
}
.datasource-select-container:disabled .datasource-pointer {
  cursor: not-allowed !important;
}

.dark-layout .datasource-select-container {
  color: #d0d2d6;
}

.datasource-select-container .datasource-pointer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.datasource-select-container .datasource-title {
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}

.datasource-select-container .datasource-description {
  color: #397bff;
}

.datasource-select-container .datasource-select {
  position: absolute;
  left: 0;
  top: 100%;
  transform: scale(0);
  opacity: 0;
  transform-origin: 150px 0;
  z-index: 3;
  border-radius: 7px;
  box-shadow: rgb(0 0 0 / 25%) 0px 25px 50px -12px;
  padding: 10px;
  transition: transform 0.4s, opacity 0.4s;
  margin-top: 10px;
  backdrop-filter: blur(2px);
  background-color: rgba(57, 123, 255, 0.12) !important;
}

.datasource-select-container:focus .datasource-select {
  transform: scale(1);
  opacity: 1;
}
.datasource-select-container:focus-within .datasource-select {
  transform: scale(1);
  opacity: 1;
}

.datasource-select-container:focus .datasource-select:hover {
  opacity: 1;
}
.datasource-select-container:focus-within .datasource-select:hover {
  opacity: 1;
}

.datasource-select-container table {
  width: 100%;
  border-radius: 7px;
  overflow: hidden;
  margin-bottom: 0;
}

table {
  background-color: #fff;
}

.datasource-select-container th {
  padding: 10px;
  border: none;
  text-align: left;
  text-transform: uppercase;
}

.datasource-select-container td {
  padding: 0;
  border: none;
  text-align: left;
  max-width: 200px;
  vertical-align: top;
  border-right: 2px solid #f3f2f7 !important;
}

.dark-layout .datasource-select-container td {
  border-right: 2px solid #343d55 !important;
}

.datasource-select-container td:last-child {
  border-right: none !important;
}

.datasource-select-container .datasource-option {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.datasource-select-container .datasource-option:hover {
  background-color: #eeedfd;
}

.datasource-select-container .datasource-option.active {
  background-color: #d8dffd;
}

.dark-layout .datasource-select-container .datasource-option:hover {
  background-color: #31375a;
}

.dark-layout .datasource-select-container .datasource-option.active {
  background-color: #323f6e;
}

.datasource-select-container .datasource-option .datasource-content {
  flex-grow: 1;
  min-width: 0;
}

.datasource-select-container .datasource-option .datasource-content .datasource-name {
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.5;
}

.datasource-select-container .item-option {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.datasource-select-container .item-option:hover {
  background-color: #eeedfd;
}

.datasource-select-container .item-option.active {
  background-color: #d8dffd;
}

.dark-layout .datasource-select-container .item-option:hover {
  background-color: #31375a;
}

.dark-layout .datasource-select-container .item-option.active {
  background-color: #323f6e;
}

.datasource-select-container .item-option .item-content {
  flex-grow: 1;
  min-width: 0;
}

.datasource-select-container .item-option .item-content .item-name {
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.datasource-select-container .count-label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.datasource-select-container .custom-scrollbar {
  min-height: 52px;
  max-height: 500px;
  overflow-y: auto;
}

.datasource-select-container .datasource-media {
  min-width: 0;
}

.datasource-select-container .datasource-media .media-body {
  min-width: 0;
}

.datasource-select-container .datasource-media p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
