<template>
  <div class="h-100 common-datasource-tools unit-converter-modal">
    <!-- <b-modal size="xl" title="Unit Converter Tool" centered ok-only :hide-footer="true" :ok-title="$t('save')" ref="unitConverterModal" :modal-class="'unit-converter-modal'" :body-class="'custom-body'" button-size="sm" @show="resetState">
    <template #modal-header> -->
    <div class="tools-header">
      <b-form-group class="mr-1 mb-0">
        <TimeseriesSelect v-model="selected" />
      </b-form-group>
      <b-form-group class="mb-0 mr-1">
        <label class="text-primary tool-custom-label text-nowrap">{{ $t('add_column') }}</label>
        <b-button variant="outline-success" size="sm" style="padding: 3px 7px" :disabled="!datasource" @click="addColumn">
          <component :is="require('/static/images/tools/formula/add-column.svg').default" :style="{ width: '20px', height: '20px' }" />
        </b-button>
      </b-form-group>
      <b-form-group class="mb-0">
        <label class="text-primary tool-custom-label">{{ $t('file_name') }}</label>
        <b-form-input :placeholder="$t('file_name')" v-model.trim="filename" size="sm" style="height: 28px" />
      </b-form-group>
      <b-form-group class="ml-auto mb-0">
        <b-button variant="success" size="sm" style="padding: 14px 28px" :disabled="!datasource || !filename" @click="save">{{ $t('save') }}</b-button>
      </b-form-group>
    </div>
    <!-- <button type="button" class="close" @click="close">&times;</button>
    </template> -->
    <div class="tools-body">
      <div class="d-flex h-100">
        <div style="flex-grow: 1; min-width: 0">
          <DatatableBody :modalType="null" :action="null" :rowsData="null" :filteredIndex="null" ref="datatableBody" :columns="columns" :updateDatatable="updateDatatable" :dsType="datasourceType" @getData="getDataTable" :itemFilterLength="itemFilterLength" :filtered="filteredData" :itemLength="itemLength" @edit-header="editHeader" @edit-column="editColumn" @delete-column="deleteColumn" />
        </div>

        <div style="width: 1px; margin: 0 1rem; flex-shrink: 0" class="bg-secondary"></div>

        <div style="overflow-y: auto" class="calculator custom-scrollbar">
          <div class="d-flex h-100">
            <div class="category d-flex flex-column h-100 mr-1">
              <b-button @click="setMethodConverter(method)" variant="relief-secondary" :style="{ backgroundColor: methodSelected === method.value ? 'rgba(40,199,111,.2)' : isDark ? '#283046' : '#fff' }" class="btn-category" v-for="method in unitMethods" :key="method.value">
                <div class="method-icon">
                  <img :src="method.icon" :style="{ width: '32px', height: '32px' }" class="tool-icon" />
                </div>
                <div class="method-name" :style="{ color: methodSelected === method.value ? '#28c76f' : '#82868b' }">{{ $t(method.value) }}</div>
              </b-button>
            </div>

            <div class="formula w-100">
              <div class="title">{{ $t('source_column') }}</div>
              <b-form-group>
                <vue-select v-model="sourceColoumSelected" :reduce="(data) => data.field" :options="sourceColumns" :placeholder="$t('select_column')" />
              </b-form-group>
              <div style="margin-right: 0; width: fit-content">
                <b-form-group>
                  <b-form-checkbox class="treat-na-as-0" v-model="treatNAas0">{{ $t('treat_NA_as_0') }}</b-form-checkbox>
                </b-form-group>
              </div>
              <div class="title">{{ $t('conversion_list') }}</div>
              <b-form-group>
                <div class="converter-select custom-scrollbar">
                  <b-badge v-for="method in unitConverter[methodSelected]" :key="method.value" @click="setUnitConverterMethod(method)" :variant="unitConverterSelected === method.value ? 'light-success' : method.label.startsWith(unitEditing) ? 'light-primary' : 'light-secondary'" class="unit-converter" :class="[{ 'break-line': !method.value }, 'unit-converter']">
                    <span v-if="method.value"> {{ $t(method.label.split(' ')[0]).toLowerCase() + ' ' + method.label.split(' ')[1] + ' ' + $t(method.label.split(' ')[2]).toLowerCase() }}</span>
                    <div v-else style="height: 15px"></div>
                  </b-badge>
                </div>
              </b-form-group>
              <div class="title">{{ $t('converter') }}</div>
              <b-form-group class="mb-0 position-relative">
                <b-form-input size="lg" id="from" class="bg-light-secondary input-demo" v-model="demoVal" />
                <div class="converter-infor">{{ $t(unitConverterFrom) }}</div>
              </b-form-group>
              <div class="equal">=</div>

              <b-form-group class="position-relative" style="margin-bottom: 5px">
                <b-form-input size="lg" class="bg-light-secondary input-demo" id="to" disabled :value="convertUnit(demoVal, multipleBy, unitConverterSelected)" />
                <div style="text-align: right">{{ $t(unitConverterTo) }}</div>
              </b-form-group>

              <div class="note" v-if="multipleBy">
                <span class="title">{{ $t('formula') }}</span> <span style="opacity: 0.8; font-size: 12px">: {{ $t('multiple_by', { value: multipleBy }) }} </span>
              </div>
              <b-form-group class="ml-auto mb-0 unit-select" :disabled="!sourceColoumSelected">
                <b-button variant="success" @click="calculate" size="sm" style="padding: 14px 28px">{{ $t('calculate') }}</b-button>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- </b-modal> -->
</template>

<script>
const _ = require('lodash')
import ScaleMixin from '@/mixins/ScaleMixin'
import DatatableBody from '../tools/DatatableBody.vue'
import TimeseriesSelect from './TimeseriesSelect.vue'
import FileMixin from '@/mixins/FileMixin.js'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import convert from 'convert-units'
import { v4 as uuidv4 } from 'uuid'
import { ThemeConfig } from '@/mixins/ThemeMixin.js'
import { ACTION_LOG } from '@/constants/actionLog'

const METHODS = [
  {
    icon: require('/static/images/tools/unit/time.png'),
    value: 'time',
  },
  {
    icon: require('/static/images/tools/unit/distance.png'),
    value: 'distance',
  },
  {
    icon: require('/static/images/tools/unit/mass.png'),
    value: 'mass',
  },
  {
    icon: require('/static/images/tools/unit/velocity.png'),
    value: 'velocity',
  },
  {
    icon: require('/static/images/tools/unit/volume.png'),
    value: 'volume',
  },
  {
    icon: require('/static/images/tools/unit/area.png'),
    value: 'area',
  },
  {
    icon: require('/static/images/tools/unit/temperature.png'),
    value: 'temperature',
  },
]
const UNIT_CONVERTER = {
  time: [
    { multiple: '24', value: 'd|h', label: 'day ➡️ hour' },
    { multiple: '1440', value: 'd|min', label: 'day ➡️ minute' },
    { multiple: '86400', value: 'd|s', label: 'day ➡️ sec' },
    { value: null, label: 'break line' },
    { multiple: '0.041666667', value: 'h|d', label: 'hour ➡️ day' },
    { multiple: '60', value: 'h|min', label: 'hour ➡️ minute' },
    { multiple: '3600', value: 'h|s', label: 'hour ➡️ sec' },
    { value: null, label: 'break line' },

    { multiple: '0.000694444', value: 'min|d', label: 'minute ➡️ day' },
    { multiple: '0.016666667', value: 'min|h', label: 'minute ➡️ hour' },
    { multiple: '60', value: 'min|s', label: 'minute ➡️ sec' },
    { value: null, label: 'break line' },

    { multiple: '1.15741E-05', value: 's|d', label: 'sec ➡️ day' },
    { multiple: '0.000277778', value: 's|h', label: 'sec ➡️ hour' },
    { multiple: '0.016666667', value: 's|min', label: 'sec ➡️ minute' },
  ],
  distance: [
    { multiple: '160934', value: 'mi|cm', label: 'mile ➡️ cm' },
    { multiple: '1609000', value: 'mi|mm', label: 'mile ➡️ mm' },
    { multiple: '5280', value: 'mi|ft', label: 'mile ➡️ ft' },
    { multiple: '63360', value: 'mi|in', label: 'mile ➡️ inch' },
    { multiple: '1.60934', value: 'mi|km', label: 'mile ➡️ km' },
    { multiple: '1609.34', value: 'mi|m', label: 'mile ➡️ m' },
    { multiple: '1760', value: 'mi|yd', label: 'mile ➡️ yard' },
    { value: null, label: 'break line' },

    { multiple: '30.47998781', value: 'ft|cm', label: 'ft ➡️ cm' },
    { multiple: '304.7998781', value: 'ft|mm', label: 'ft ➡️ mm' },
    { multiple: '12', value: 'ft|in', label: 'ft ➡️ inch' },
    { multiple: '0.000304799', value: 'ft|km', label: 'ft ➡️ km' },
    { multiple: '0.000189394', value: 'ft|mi', label: 'ft ➡️ mile' },
    { multiple: '0.304799415', value: 'ft|m', label: 'ft ➡️ m' },
    { multiple: '0.333333', value: 'ft|yd', label: 'ft ➡️ yard' },
    { value: null, label: 'break line' },

    { multiple: '91.44', value: 'yd|cm', label: 'yard ➡️ cm' },
    { multiple: '914.4', value: 'yd|mm', label: 'yard ➡️ mm' },
    { multiple: '3', value: 'yd|ft', label: 'yard ➡️ ft' },
    { multiple: '36.00001152', value: 'yd|in', label: 'yard ➡️ inch' },
    { multiple: '0.0009144', value: 'yd|km', label: 'yard ➡️ km' },
    { multiple: '0.000568182', value: 'yd|mi', label: 'yard ➡️ mile' },
    { multiple: '0.9144', value: 'yd|m', label: 'yard ➡️ m' },
    { value: null, label: 'break line' },

    { multiple: '2.54', value: 'in|cm', label: 'inch ➡️ cm' },
    { multiple: '25.4', value: 'in|mm', label: 'inch ➡️ mm' },
    { multiple: '0.083333333', value: 'in|ft', label: 'inch ➡️ ft' },
    { multiple: '2.54E-05', value: 'in|km', label: 'inch ➡️ km' },
    { multiple: '1.57828E-05', value: 'in|mi', label: 'inch ➡️ mile' },
    { multiple: '0.0254', value: 'in|m', label: 'inch ➡️ m' },
    { multiple: '0.0277778', value: 'in|yd', label: 'inch ➡️ yard' },
    { value: null, label: 'break line' },

    { multiple: '100000', value: 'km|cm', label: 'km ➡️ cm' },
    { multiple: '1000000', value: 'km|mm', label: 'km ➡️ mm' },
    { multiple: '3280.84', value: 'km|ft', label: 'km ➡️ ft' },
    { multiple: '39370.1', value: 'km|in', label: 'km ➡️ inch' },
    { multiple: '0.621371', value: 'km|mi', label: 'km ➡️ mile' },
    { multiple: '1000', value: 'km|m', label: 'km ➡️ m' },
    { multiple: '1093.61', value: 'km|yd', label: 'km ➡️ yard' },
    { value: null, label: 'break line' },

    { multiple: '100', value: 'm|cm', label: 'm ➡️ cm' },
    { multiple: '1000', value: 'm|mm', label: 'm ➡️ mm' },
    { multiple: '3.28084', value: 'm|ft', label: 'm ➡️ ft' },
    { multiple: '39.3701', value: 'm|in', label: 'm ➡️ inch' },
    { multiple: '0.001', value: 'm|km', label: 'm ➡️ km' },
    { multiple: '0.000621371', value: 'm|mi', label: 'm ➡️ mile' },
    { multiple: '1.09361', value: 'm|yd ', label: 'm ➡️ yard' },
    { value: null, label: 'break line' },

    { multiple: '10', value: 'cm|mm', label: 'cm ➡️ mm' },
    { multiple: '0.03281', value: 'cm|ft', label: 'cm ➡️ ft' },
    { multiple: '0.393700787', value: 'cm|in', label: 'cm ➡️ inch' },
    { multiple: '0.00001', value: 'cm|km', label: 'cm ➡️ km' },
    { multiple: '6.2137E-06', value: 'cm|mi', label: 'cm ➡️ mile' },
    { multiple: '0.01', value: 'cm|m', label: 'cm ➡️ m' },
    { multiple: '0.0109361', value: 'cm|yd', label: 'cm ➡️ yard' },
    { value: null, label: 'break line' },

    { multiple: '0.1', value: 'mm|cm', label: 'mm ➡️ cm' },
    { multiple: '0.003280834', value: 'mm|ft', label: 'mm ➡️ ft' },
    { multiple: '0.039370079', value: 'mm|in', label: 'mm ➡️ inch' },
    { multiple: '0.000001', value: 'mm|km', label: 'mm ➡️ km' },
    { multiple: '6.2137E-07', value: 'mm|mi', label: 'mm ➡️ mile' },
    { multiple: '0.001', value: 'mm|m', label: 'mm ➡️ m' },
    { multiple: '0.00109361', value: 'mm|yd', label: 'mm ➡️ yard' },
  ],
  mass: [
    { multiple: '1000000', value: 'mt|g', label: 'tonne ➡️ g' },
    { multiple: '1000', value: 'mt|kg', label: 'tonne ➡️ kg' },
    { multiple: '1000000000', value: 'mt|mg', label: 'tonne ➡️ mg' },
    { multiple: '2204.62', value: 'mt|lb', label: 'tonne ➡️ lbs' },
    { multiple: '35274', value: 'mt|oz', label: 'tonne ➡️ oz' },
    // { multiple: '1', value: 'mt|mt', label: 'tonne ➡️ tonne' },
    { value: null, label: 'break line' },

    { multiple: '453.59', value: 'lb|g', label: 'lbs ➡️ g' },
    { multiple: '0.45359237', value: 'lb|kg', label: 'lbs ➡️ kg' },
    { multiple: '453592', value: 'lb|mg', label: 'lbs ➡️ mg' },
    // { multiple: '1', value: 'lb|lb', label: 'lbs ➡️ lbs' },
    { multiple: '16', value: 'lb|oz', label: 'lbs ➡️ oz' },
    { multiple: '0.000453593', value: 'lb|mt', label: 'lbs ➡️ tonne' },
    { value: null, label: 'break line' },

    { multiple: '28.35', value: 'oz|g', label: 'oz ➡️ g' },
    { multiple: '0.02835', value: 'oz|kg', label: 'oz ➡️ kg' },
    { multiple: '28349.5', value: 'oz|mg', label: 'oz ➡️ mg' },
    { multiple: '0.0625', value: 'oz|lb', label: 'oz ➡️ lbs' },
    // { multiple: '1', value: 'oz|oz', label: 'oz ➡️ oz' },
    { multiple: '2.83495E-05', value: 'oz|mt', label: 'oz ➡️ tonne' },
    { value: null, label: 'break line' },

    { multiple: '1000', value: 'kg|g', label: 'kg ➡️ g' },
    // { multiple: '1', value: 'kg|kg', label: 'kg ➡️ kg' },
    { multiple: '1000000', value: 'kg|mg', label: 'kg ➡️ mg' },
    { multiple: '2.20462', value: 'kg|lb', label: 'kg ➡️ lbs' },
    { multiple: '35.274', value: 'kg|oz', label: 'kg ➡️ oz' },
    { multiple: '0.001', value: 'kg|mt', label: 'kg ➡️ tonne' },
    { value: null, label: 'break line' },

    // { multiple: '1', value: 'g|g', label: 'g ➡️ g' },
    { multiple: '0.001', value: 'g|kg', label: 'g ➡️ kg' },
    { multiple: '1000', value: 'g|mg', label: 'g ➡️ mg' },
    { multiple: '0.00220462', value: 'g|lb', label: 'g ➡️ lbs' },
    { multiple: '0.035274', value: 'g|oz', label: 'g ➡️ oz' },
    { multiple: '0.000001', value: 'g|mt', label: 'g ➡️ tonne' },
    { value: null, label: 'break line' },

    { multiple: '0.001', value: 'mg|g', label: 'mg ➡️ g' },
    { multiple: '0.000001', value: 'mg|kg', label: 'mg ➡️ kg' },
    // { multiple: '1', value: 'mg|mg', label: 'mg ➡️ mg' },
    { multiple: '2.20462E-06', value: 'mg|lb', label: 'mg ➡️ lbs' },
    { multiple: '0.000035274', value: 'mg|oz', label: 'mg ➡️ oz' },
    { multiple: '0.000000001', value: 'mg|mt', label: 'mg ➡️ tonne' },
  ],
  velocity: [
    { multiple: '0.277777778', value: 'km/h|m/s', label: 'km/h ➡️ m/s' },
    { multiple: '3.6', value: 'm/s|km/h', label: 'm/s ➡️ km/h' },
  ],
  volume: [
    { multiple: '1000000', value: 'm3|ml', label: 'm3 ➡️ ml' },
    { multiple: '1000', value: 'm3|l', label: 'm3 ➡️ L' },
    // { multiple: '1', value: 'm3|m3', label: 'm3 ➡️ m3' },
    { value: null, label: 'break line' },

    // { multiple: '1', value: 'ml|ml', label: 'ml ➡️ ml' },
    { multiple: '0.001', value: 'ml|l', label: 'ml ➡️ L' },
    { multiple: '0.000001', value: 'ml|m3', label: 'ml ➡️ m3' },
    { value: null, label: 'break line' },

    { multiple: '1000', value: 'l|ml', label: 'L ➡️ ml' },
    // { multiple: '1', value: 'l|l', label: 'L ➡️ L' },
    { multiple: '0.001', value: 'l|m3', label: 'L ➡️ m3' },
  ],
  area: [
    { multiple: '0.004046863', value: 'ac|km2', label: 'acre ➡️ km2' },
    { multiple: '4046.86', value: 'ac|m2', label: 'acre ➡️ m2' },
    // { multiple: '1', value: 'ac|ac', label: 'acre ➡️ acre' },
    { multiple: '0.404686', value: 'ac|ha', label: 'acre ➡️ hectare' },
    { value: null, label: 'break line' },

    { multiple: '1000000', value: 'km2|m2', label: 'km2 ➡️ m2' },
    // { multiple: '1', value: 'km2|km2', label: 'km2 ➡️ km2' },
    { multiple: '247.105', value: 'km2|ac', label: 'km2 ➡️ acre' },
    { multiple: '100', value: 'km2|ha', label: 'km2 ➡️ hectare' },
    { value: null, label: 'break line' },

    { multiple: '0.01', value: 'ha|km2', label: 'hectare ➡️ km2' },
    { multiple: '10000', value: 'ha|m2', label: 'hectare ➡️ m2' },
    { multiple: '2.47105163', value: 'ha|ac', label: 'hectare  ➡️ acre' },
    // { multiple: '1', value: 'ha|ha', label: 'hectare ➡️ hectare' },
    { value: null, label: 'break line' },

    // { multiple: '1', value: 'm2|m2', label: 'm2 ➡️ m2' },
    { multiple: '0.000001', value: 'm2|km2', label: 'm2 ➡️ km2' },
    { multiple: '0.000247105', value: 'm2|ac', label: 'm2 ➡️ acre' },
    { multiple: '0.0001', value: 'm2|ha', label: 'm2 ➡️ hectare' },
  ],
  temperature: [
    { multiple: null, value: 'K|C', label: 'K ➡️ degC' },
    { multiple: null, value: 'K|F', label: 'K ➡️ degF' },
    { multiple: null, value: 'F|C', label: 'degF ➡️ degC' },
    { multiple: null, value: 'F|K', label: 'degF ➡️ K' },
    { multiple: null, value: 'C|F', label: 'degC ➡️ degF' },
    { multiple: null, value: 'C|K', label: 'degC ➡️ K' },
  ],
}

export default {
  components: { DatatableBody, TimeseriesSelect, ToastificationContent },
  mixins: [ScaleMixin, ThemeConfig, FileMixin],
  mounted() {
    this.rows = []
  },
  data() {
    return {
      selected: null,
      columns: [],
      sourceColumns: [],
      filename: '',
      error: null,
      addedColumn: [],
      itemFilterLength: 0,
      itemLength: 0,
      filteredData: [],
      dataFilterStore: [],
      updateDatatable: 0, //update view datatableBody when rows change(add column, ...)
      datasourceType: '',
      editing: null,
      rowEditingIndex: null,
      unitConverterSelected: UNIT_CONVERTER['time'][0].value,
      methodSelected: 'time',
      unitMethods: _.cloneDeep(METHODS),
      unitConverter: _.cloneDeep(UNIT_CONVERTER),
      sourceColoumSelected: '',
      demoVal: 1,
      unitConverterFrom: 'day',
      unitConverterTo: 'hour',
      treatNAas0: false,
      multipleBy: UNIT_CONVERTER['time'][0].multiple,
      unitEditing: '_',
    }
  },
  computed: {
    datasources() {
      return this.$store.state.datasource.datasources
    },
    datasource() {
      let datasource = this.datasources.find((d) => d.id === this.selected)
      if (!datasource) return null
      return { ...datasource, ...this.$db[datasource.id] }
    },
    datasourceTools() {
      return this.$store.state.map.datasourceTools
    },
  },
  watch: {
    async selected() {
      await this.init()
      this.updateDatatable++
    },
    editing(editing) {
      let columns = []
      this.columns.forEach((c) => {
        columns.push({ ...c, editable: c.field === editing, highlight: c.field === editing })
      })
      this.columns = columns
      let added = this.addedColumn.find((c) => c.field === this.editing)
      if (added && added.optionSeleted && Object.keys(added.optionSeleted).length !== 0) {
        this.demoVal = added.optionSeleted.demoVal
        this.methodSelected = added.optionSeleted.methodSelected
        this.sourceColoumSelected = added.optionSeleted.sourceColoumSelected
        this.unitConverterFrom = added.optionSeleted.unitConverterFrom
        this.unitConverterSelected = added.optionSeleted.unitConverterSelected
        this.unitConverterTo = added.optionSeleted.unitConverterTo
      }
    },
    sourceColoumSelected(value) {
      let colSelected = this.columns.find((col) => col.field === value)
      this.unitEditing = colSelected ? colSelected.label[colSelected.label.length - 1] : '_'
      if (!this.unitEditing) this.unitEditing = '_'
    },
    datasourceTools() {
      this.resetState()
    },
  },
  methods: {
    filtered(dataFilter, searchTerm) {
      let filtered = dataFilter.filter((row) => {
        let valid = false
        if (this.datasourceType !== 'timeseries') {
          for (const key in row) {
            let value = row[key] === null ? 'NA' : row[key].toString()
            if (value.toLowerCase().includes(searchTerm.toLowerCase())) {
              valid = true
              break
            }
          }
        } else {
          for (let i = 0; i < row.length; i++) {
            let value = row[i] === null ? 'NA' : row[i].toString()
            if (value.toLowerCase().includes(searchTerm.toLowerCase())) {
              valid = true
              break
            }
          }
        }

        return valid
      })
      return filtered
    },

    getDataTable(searchTerm, from, to, isGetDataToSearch) {
      if (searchTerm === '') {
        this.itemFilterLength = this.itemLength
        this.filteredData = this.filtered(this.rows.slice(+from, +to), searchTerm)
      } else {
        if (isGetDataToSearch) this.dataFilterStore = this.filtered([...this.rows], searchTerm)
        if (!this.dataFilterStore.length) {
          from = from === -1 ? 0 : from
          to = to === 0 ? 20 : to
          this.itemFilterLength = this.itemLength
          this.filteredData = this.filtered(this.rows.slice(+from, +to), '')
        } else {
          this.itemFilterLength = this.dataFilterStore.length
          this.filteredData = this.dataFilterStore.slice(+from, +to)
        }
      }
    },
    // open() {
    //   this.$refs.unitConverterModal.show();
    // },
    // close() {
    //   this.$refs.unitConverterModal.hide();
    // },
    resetState() {
      this.selected = null
      this.columns = []
      this.rows = []
      this.error = null
      this.addedColumn = []
      this.filename = ''
      this.sourceColoumSelected = ''
      this.unitConverterSelected = UNIT_CONVERTER['time'][0].value
      this.methodSelected = 'time'
      this.sourceColumns = []
      this.demoVal = 1
      this.unitEditing = '_'
      this.multipleBy = UNIT_CONVERTER['time'][0].multiple
      this.unitConverterFrom = 'day'
      this.unitConverterTo = 'hour'
      this.treatNAas0 = false
    },
    setMethodConverter(method) {
      this.methodSelected = method.value
      this.unitConverterSelected = this.unitConverter[method.value][0].value
      this.unitConverterFrom = this.unitConverter[method.value][0].label.split(' ')[0]
      this.unitConverterTo = this.unitConverter[method.value][0].label.split(' ')[2]
      this.multipleBy = this.unitConverter[method.value][0].multiple
    },
    editColumn(field) {
      if (this.editing === field) return
      let added = this.addedColumn.find((c) => c.field === field)
      if (!added) return
      this.editing = field
      this.rowEditingIndex = this.columns.findIndex((col) => col.field === field)
    },
    editHeader({ field, index, value }) {
      let colIndex = this.columns.findIndex((col) => col.field === field)
      if (colIndex !== -1) {
        let newCol = _.cloneDeep(this.columns[colIndex])
        newCol.label[index] = value.trim()
        this.columns.splice(colIndex, 1, newCol)
      }
    },
    deleteColumn(field, colIdx) {
      this.addedColumn = this.addedColumn.filter((c) => c.field !== field)
      this.columns = this.columns.filter((c) => c.field !== field)
      let rows = _.cloneDeep(this.rows)
      for (let i = 0; i < rows.length; i++) {
        rows[i].splice(colIdx, 1)
      }
      this.rows = rows
      try {
        this.rowEditingIndex = rows[0].length
      } catch {}
      this.updateDatatable++
    },
    transpose(matrix) {
      return matrix[0].map((_, columnIndex) => matrix.map((row) => (row && row[columnIndex] != null ? row[columnIndex] : null)))
    },
    async init() {
      if (!this.datasource) {
        this.columns = []
        this.sourceColumns = []
        this.rows = []
        this.itemFilterLength = 0
        this.itemLength = 0
        this.filteredData = []
        this.dataFilterStore = []
        this.updateDatatable = 0
        return
      }

      if (this.datasource.type === 'timeseries') {
        let datasource = this.$db[this.datasource.id]
        let items = this.datasource.items
        let columns = [{ label: datasource.header, field: 'date' }]
        let sourceColumns = []
        let rowsOriginal = {}
        let rowsConvert = {}
        rowsOriginal = await this.selectAll(this.datasource.id)
        rowsConvert['dates'] = rowsOriginal['dates']
        for (const key in datasource.columns) {
          let [location, item] = key.split('*')
          rowsConvert[key.replace('*', '-')] = rowsOriginal[key.replace('*', '-')]
          sourceColumns.push({ label: datasource.columns[key].unit ? `${location}-${item} (${datasource.columns[key].unit})` : `${location}-${item}`, field: key })
          columns.push({ label: datasource.header.length === 3 ? [location, item, datasource.columns[key].unit] : [location, datasource.columns[key].autoGroup, item, datasource.columns[key].unit], field: key, type: 'number' })
        }

        this.columns = columns
        this.sourceColumns = sourceColumns
        this.rows = this.transpose(Object.values(rowsConvert))
        this.itemLength = this.rows.length
        this.datasourceType = 'timeseries'
      }
    },
    addColumn() {
      setTimeout(() => {
        this.$refs.datatableBody.scrollRight()
      }, 0)

      let field = uuidv4()
      let datasource = this.$db[this.datasource.id]
      let placeholder = datasource.header.length === 3 ? ['enter_location', 'enter_item', 'enter_unit'] : ['enter_location', 'enter_group', 'enter_item', 'enter_unit']
      this.columns.push({ label: datasource.header.length === 3 ? ['', '', ''] : ['', '', '', ''], field, type: 'number', success: true, deleteable: true, placeholder })
      this.addedColumn.push({
        field,
        optionSeleted: {},
      })
      let rows = _.cloneDeep(this.rows)
      for (let i = 0; i < rows.length; i++) {
        rows[i].push(0)
      }
      this.rows = rows
      this.updateDatatable++
      this.editing = field
      this.rowEditingIndex = rows[0].length - 1
    },
    calculate() {
      if (!this.editing) return
      if (!this.sourceColoumSelected) this.$toast({ component: ToastificationContent, props: { title: this.$t('unit_converter_err'), text: this.$t('unit_converter_err_mess'), icon: 'SlashIcon', variant: 'danger' } })
      else {
        const naValue = this.treatNAas0 ? 0 : null
        const rows = _.cloneDeep(this.rows)
        let colsData = this.transpose(rows)
        let colIdxSelected = this.columns.findIndex((col) => col.field === this.sourceColoumSelected)
        // this.unitEditing = colEditing ? colEditing.label[colEditing.label.length - 1] : '_';
        for (let i = 0; i < rows.length; i++) {
          let valueTemp = colsData[colIdxSelected][i]
          valueTemp = !this.treatNAas0 ? valueTemp : valueTemp ? valueTemp : naValue
          rows[i][this.rowEditingIndex] = valueTemp !== null ? this.convertUnit(valueTemp, this.multipleBy, this.unitConverterSelected) : naValue
          // rows[i][this.rowEditingIndex] = colsData[colIdxSelected][i] !== null ? this.convertUnit(colsData[colIdxSelected][i], this.multipleBy, this.unitConverterSelected) : naValue;
        }
        this.rows = rows
        this.updateDatatable++
        let addedIndex = this.addedColumn.findIndex((c) => c.field === this.editing)
        this.addedColumn.splice(addedIndex, 1, {
          field: this.editing,
          optionSeleted: {
            methodSelected: this.methodSelected,
            unitConverterSelected: this.unitConverterSelected,
            demoVal: this.demoVal,
            sourceColoumSelected: this.sourceColoumSelected,
            unitConverterFrom: this.unitConverterFrom,
            unitConverterTo: this.unitConverterTo,
            multipleBy: this.multipleBy,
          },
        })
      }
    },
    async save() {
      const GROUP_IDX = this.columns[0].label.findIndex((c) => c === 'GROUP')
      const ITEM_IDX = this.columns[0].label.findIndex((c) => c === 'ITEM')
      const UNIT_IDX = this.columns[0].label.findIndex((c) => c === 'UNIT')
      // validate column name before save
      for (let i = 0; i < this.columns.length; i++) {
        if (!this.columns[i].label[0] || !this.columns[i].label[ITEM_IDX]) {
          this.$toast({ component: ToastificationContent, props: { title: 'Save failed', text: 'Empty column name', icon: 'SlashIcon', variant: 'danger' } })
          return
        }
      }

      // save to current datasource
      // convert array of row object to 1 datasource object and add to store
      const id = uuidv4()
      const name = this.filename + (this.filename.endsWith('.csv') ? '' : '.csv')
      const type = this.datasource.type
      const dates = this.rows.map((r) => r[0])
      let columns = {}
      let autoGroups = {}
      let tmpGroup = null // temp variable

      this.columns.forEach((column, index) => {
        if (column.field === 'date') return
        let field = column.deleteable ? `${column.label[0]}*${column.label[ITEM_IDX]}` : column.field
        columns[field] = { nullCount: 0, autoGroup: column.label[GROUP_IDX], unit: column.label[UNIT_IDX] }
        tmpGroup = GROUP_IDX !== -1 ? this.getGroupName(column.label[GROUP_IDX]) : []
        tmpGroup.map((grName) => {
          if (!grName) return
          if (!autoGroups[grName]) autoGroups[grName] = []
          autoGroups[grName].push(field)
        })
      })
      const encoding = this.datasource.encoding
      const storage_mode = 'RAM'
      let data = {}
      const keysCol = Object.keys(columns)
      for (let i = 0; i < keysCol.length; i++) {
        for (let j = 0; j < this.rows.length; j++) {
          if (!data[keysCol[i].replace('*', '-')]) data[keysCol[i].replace('*', '-')] = []
          data[keysCol[i].replace('*', '-')].push(this.rows[j][i + 1])
        }
      }
      let itemArray = [...new Set(this.columns.slice(1).map((column) => column.label[ITEM_IDX]))]
      let items = {}
      itemArray.forEach((item) => {
        if (!item) return
        let obj = { min: Infinity, max: -Infinity, unit: '' }
        let groupItem = Object.keys(data)
          .filter((key) => key.endsWith(`-${item}`))
          .reduce((obj, key) => {
            obj[key] = data[key]
            return obj
          }, {})
        obj.min = _.min([].concat(...Object.values(groupItem)))
        obj.max = _.max([].concat(...Object.values(groupItem)))
        // unit
        let index = this.columns.findIndex((column) => column.label[ITEM_IDX] === item)
        obj.unit = this.columns[index].label[UNIT_IDX]
        items[item] = obj
      })
      const locations = this.datasource.locations
      let datasourceObject = { id, name, type, dates, columns, encoding, locations, items, storage_mode, data, csvData: this.rows, header: [...this.columns[0].label], autoGroups }
      await this.storeData(datasourceObject)
      //CREATE OBJ FILE
      const file = await this.createObjectFile(datasourceObject)
      //SAVE TO TEMP FILE
      datasourceObject.file = file
      datasourceObject.size = file.size
      //UPDATE DATASOURCE
      await this.addDatasource(datasourceObject)
      this.updateDatatable++
      let messageTitle = 'create_from_unit_Converter'
      let message = this.$t(messageTitle, { move: name })
      this.log.info({ message, id: ACTION_LOG[7].id, messageTitle, move: name })
      // this.log.info({ message: `Create and add datasource '${name}' from datasource tool [Unit Converter]`, id: ACTION_LOG[7].id });
      // this.close();
    },
    getGroupName(str) {
      return str.toString().split('|')
    },
    convertUnit(value, multipleBy, unitConverterSelected) {
      let [convertFrom, convertTo] = unitConverterSelected.split('|')
      let num = multipleBy ? +value * +multipleBy : convert(value).from(convertFrom).to(convertTo)
      try {
        num = Number(num).toFixed(6)
      } catch {}
      return num
    },
    setUnitConverterMethod(method) {
      if (!method.value) return
      this.unitConverterSelected = method.value
      this.unitConverterFrom = method.label.split(' ')[0]
      this.unitConverterTo = method.label.split(' ')[2]
      this.multipleBy = method.multiple
    },
  },
}
</script>

<style>
.unit-converter-modal .calculator {
  width: 370px;
  flex-shrink: 0;
}
</style>

<style scoped>
.calculator .btn-category {
  margin-bottom: 1rem;
  justify-content: center;
  width: 100px;
  padding: 9px 6px;
  background-color: unset;
  display: flex;
  flex-direction: column;
  color: #82868b;
  align-items: center;
}
.calculator .btn-category:hover {
  background-color: unset;
}
.calculator .btn-category:focus {
  box-shadow: inset 0 -3px 0 0 rgba(34, 41, 47, 0.2);
}

.unit-select {
  float: right;
}
.calculator .method-name {
  font-size: 12px;
  margin-top: 9px;
  text-transform: uppercase;
}
.dark-layout .calculator .btn-category {
  box-shadow: 0.3rem 0.3rem 0.6rem #161d31, -0.2rem -0.2rem 0.5rem #283046;
}
.converter-select {
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-flow: row wrap;
}
.converter-select .unit-converter {
  margin: 2px;
  line-height: 1rem;
  cursor: pointer;
  height: 23px;
  user-select: none;
  font-weight: unset;
  width: calc(50% - 4px);
}
.converter-select .break-line {
  width: 100%;
  line-height: 15px;
  height: 15px;
  background-color: unset;
  color: unset;
  margin: 0;
  padding: 0;
}
.dark-layout .input-demo {
  color: #fff !important;
}
.input-demo {
  text-align: right;
  border: 1px solid transparent;
  border-color: transparent !important;
  font-size: 25px;
}
.treat-na-as-0 {
  cursor: pointer;
  user-select: none;
}
.equal {
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  margin: 5px 0;
  line-height: 25px;
}
.input-demo:disabled {
  opacity: 0.5;
}
.converter-infor {
  position: absolute;
  right: 0;
}
.note {
  margin: 10px 0;
}
.calculator .title {
  font-weight: bold;
  margin-bottom: 5px;
}
</style>
