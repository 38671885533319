<template>
  <b-button variant="flat-primary" class="datasource-select-container">
    <div class="datasource-pointer" v-ripple.400="'rgba(57, 123, 255, 0.15)'">
      <b-media no-body>
        <b-media-aside>
          <b-avatar rounded size="42" variant="light-primary">
            <feather-icon size="18" icon="DatabaseIcon" style="color: #397bff" />
          </b-avatar>
        </b-media-aside>
        <b-media-body style="text-align: left">
          <h6 class="datasource-title d-flex">
            {{ $t('datasource') }}
          </h6>
          <div class="datasource-description small text-left">
            {{ mode === 'multiple' ? `${datasourceCount}/${datasourceTotal} ${$t('datasources_selected')}` : `${datasourceSingle || nothingSelected}` }}
          </div>
        </b-media-body>
      </b-media>
      <b-badge pill variant="light-primary">
        <feather-icon size="18" icon="ChevronDownIcon" />
      </b-badge>
    </div>

    <div class="datasource-select bg-light">
      <table class="table">
        <thead>
          <tr>
            <th style="width: 202px">
              <div class="d-flex justify-content-between align-items-center" style="padding-right: 5px">
                <span style="line-height: 22px">{{ $t('datasource') }}</span>
              </div>
            </th>
            <th style="width: 202px" v-if="items">
              <div class="d-flex justify-content-between align-items-center" style="padding-right: 5px">
                <span style="line-height: 22px">{{ $t('item') }}</span>
              </div>
            </th>
            <th style="width: 200px">
              <div class="d-flex justify-content-between align-items-center" style="padding-right: 5px">
                <span style="line-height: 22px">{{ $t('location') }}</span>
                <div :title="$t('choose_location')">
                  <b-form-checkbox v-show="mode === 'multiple'" :checked="allChecked === 1" :indeterminate="allChecked === 0" @click.native="checkAll" disabled></b-form-checkbox>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="custom-scrollbar">
                <div class="datasource-option" v-for="datasource in datasources" :key="datasource.id" @click="selectDatasource(datasource.id)">
                  <div class="datasource-content">
                    <div class="datasource-name" :title="datasource.name">{{ datasource.name }}</div>
                  </div>
                  <b-form-radio :checked="datasourceSelected === datasource.id ? 'B' : ''" value="B" disabled></b-form-radio>
                </div>
              </div>
            </td>
            <!-- <td>
                <div class="custom-scrollbar">
                  <div class="datasource-option" v-for="datasource in datasources" :key="datasource.id" :class="{ active: datasourceSelected === datasource.id }" @click="selectDatasource(datasource.id)">
                    <div class="datasource-content">
                      <div class="datasource-name" :title="datasource.name">
                        <feather-icon icon="RadioIcon" title="Live mode" v-if="datasource.live != null && datasource.type != 'netcdf'" @click="changeDatasourceLiveMode(datasource)" :class="[datasource.live ? 'text-danger' : 'text-secondary']" />
                        <feather-icon icon="ActivityIcon" title="Statistic Data" v-if="showStatisticData(datasource.id)" />
                        {{ datasource.name }}
                      </div>
                      <div v-if="!showStatisticData(datasource.id)">
                        <small class="count-label" v-if="$i18n.locale === 'en'">
                          <span>Select</span>
                          <b-badge :variant="itemVariant(datasource.id)">{{ itemCount(datasource.id) }}/{{ itemTotal(datasource.id) }}</b-badge>
                          <span>Items</span>
                        </small>
                        <small class="count-label" v-else>
                          <b-badge :variant="itemVariant(datasource.id)">{{ itemCount(datasource.id) }}/{{ itemTotal(datasource.id) }}</b-badge>
                          <span>{{ $t('select_items') }}</span>
                        </small>
                      </div>
                      <div v-else>
                        <small class="count-label" v-if="$i18n.locale === 'en'">
                          <span>Select</span>
                          <b-badge :variant="statisticLocationsVariant(datasource.id)">{{ statisticLocationsCount(datasource.id) }}/{{ statisticLocationsTotal(datasource.id) }}</b-badge>
                          <span>Items</span>
                        </small>
                        <small class="count-label" v-else>
                          <b-badge :variant="statisticLocationsVariant(datasource.id)">{{ statisticLocationsCount(datasource.id) }}/{{ statisticLocationsTotal(datasource.id) }}</b-badge>
                          <span>{{ $t('select_items') }}</span>
                        </small>
                      </div>
                    </div>
                    <feather-icon size="18" icon="ChevronRightIcon" style="min-width: 18px" />
                  </div>
                </div>
              </td> -->
            <td v-if="items">
              <div class="custom-scrollbar">
                <div class="item-option" v-for="item in items" :key="item" :class="{ active: itemSelected === item }" @click="selectItem(item)">
                  <div class="item-content">
                    <div class="item-name">
                      <span>{{ item }}</span>
                      <small class="text-secondary">{{ itemUnit(item) }}</small>
                    </div>
                    <!-- <small class="count-label" v-if="$i18n.locale === 'en'">
                      <span>Select</span>
                      <b-badge :variant="locationVariant(item)">{{ locationCount(item) }}/{{ locationTotal(item) }}</b-badge>
                      <span>Locations</span>
                    </small>
                    <small class="count-label" v-else>
                      <b-badge :variant="locationVariant(item)">{{ locationCount(item) }}/{{ locationTotal(item) }}</b-badge>
                      <span>{{ $t('select_locations') }}</span>
                    </small> -->
                  </div>
                  <feather-icon size="18" icon="ChevronRightIcon" style="min-width: 18px" />
                </div>
              </div>
            </td>
            <td>
              <VirtualList class="custom-scrollbar" style="overflow-y: scroll" :data-key="'id'" :data-sources="locationData" :data-component="locationComponent" :keeps="20" :extra-props="{ mode, datasourceSelected, itemSelected, locationSelected, selectLocation, nullDisabled }" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-button>
</template>

<script>
const _ = require('lodash');
import Ripple from 'vue-ripple-directive';
import VirtualList from 'vue-virtual-scroll-list';
import LocationComponent from '../../../../../common/DatasourceSelect/LocationComponent.vue';

const CHECKBOX = {
  CHECK: 1,
  UNCHECK: -1,
  INDETERMINATE: 0,
};

export default {
  props: ['value', 'nullDisabled', 'selected'],
  directives: { Ripple },
  components: { VirtualList },
  data() {
    return {
      datasourceSelected: null,
      itemSelected: null,
      locationComponent: LocationComponent,
      mode: 'multiple',
      blackList: [],
      width: 320,
      closeAfterSelect: false,
    };
  },

  computed: {
    locationSelected: {
      get() {
        return this.value.filter((d) => d.datasource);
      },
      set(locationSelected) {
        this.$emit('input', locationSelected);
      },
    },
    datasources() {
      return this.$store.state.datasource.datasources.filter((d) => d.type === 'timeseries').filter((d) => !this.blackList.includes(d.id));
    },
    items() {
      if (this.datasourceSelected == null) return [];

      let datasource = this.datasources.find((d) => d.id === this.datasourceSelected);
      const isStatisticsData = this.checkStatisticData(datasource.id);
      if (isStatisticsData && (this.$route.name === 'boxplot' || this.$route.name === 'boxplot-uplot')) return null;
      let datasourceMeta = this.$db[datasource.id];
      return Object.keys(datasourceMeta.items);
    },
    locations() {
      if (this.datasourceSelected == null) return [];
      let datasourceMeta = this.$db[this.datasourceSelected];
      const isStatisticsData = this.checkStatisticData(this.datasourceSelected);
      if (isStatisticsData) {
        return Object.keys(datasourceMeta.locations);
      } else {
        return Object.keys(datasourceMeta.columns).reduce((previous, element) => {
          let [location, item] = element.split('*');
          if (item === this.itemSelected) previous.push(location);
          return previous;
        }, []);
      }
    },
    locationData() {
      return this.locations.map((l) => ({ id: l, name: l }));
    },
    allChecked() {
      let checked = this.locationSelected.filter((s) => s.datasource === this.datasourceSelected && s.item === this.itemSelected);
      if (checked.length === 0) return CHECKBOX.UNCHECK;
      else if (checked.length === this.locations.length) return CHECKBOX.CHECK;
      else return CHECKBOX.INDETERMINATE;
    },
    nothingSelected() {
      return this.$i18n.t('nothing_selected');
    },
    datasourceCount() {
      return [...new Set(this.locationSelected.map((l) => l.datasource))].length;
    },
    datasourceTotal() {
      return this.datasources.length;
    },
    datasourceSingle() {
      if (this.locationSelected.length) {
        return `${this.locationSelected[0].location} - ${this.locationSelected[0].item} ${this.$i18n.t('selected')}`;
      }
    },
  },
  watch: {
    datasources(datasources) {
      if (datasources.length) {
        if (!datasources.some((d) => d.id === this.datasourceSelected)) {
          // this.datasourceSelected = datasources[0].id;
        }
      } else {
        this.datasourceSelected = null;
      }
      this.locationSelected = this.locationSelected.filter((l) => datasources.some((d) => d.id === l.datasource));
    },
    items(items) {
      if (!items) {
        this.itemSelected = null;
        return;
      }
      if (!!items || items.length) {
        if (!items.some((i) => i === this.itemSelected)) {
          this.itemSelected = items[0];
        }
      } else {
        this.itemSelected = null;
      }
    },
    autoLocationSelected(value) {
      const locationSelectedTmp = [...this.locationSelected, ...value];
      let uniqueLocationSelected = _.uniqWith(locationSelectedTmp, _.isEqual);
      this.locationSelected = uniqueLocationSelected;
    },
    unselectLocation(value) {
      this.locationSelected = _.filter(this.locationSelected, (obj) => {
        return value.findIndex((val) => val.datasource === obj.datasource && val.item === obj.item && val.location === obj.location) === -1;
      });
    },
  },
  methods: {
    recordCount(id) {
      return this.$db[id].dates.length;
    },
    selectDatasource(id) {
      if (this.datasourceSelected === id) {
        this.datasourceSelected = null;
      } else {
        this.datasourceSelected = null;
        this.datasourceSelected = id;
      }
      this.$emit('getSelected', this.datasourceSelected);
    },
    changeDatasourceLiveMode(datasource) {
      datasource.live = !datasource.live;
      this.$store.commit('datasource/UPDATE_DATASOURCE', datasource);
    },
    selectItem(item) {
      this.itemSelected = item;
    },
    selectLocation(location) {
      let index = this.locationSelected.findIndex((l) => l.datasource === this.datasourceSelected && l.item === this.itemSelected && l.location === location);
      if (this.mode === 'multiple') {
        // In multiple mode:
        // Push if not exist
        // Else slice
        if (index === -1) {
          this.locationSelected = [...this.locationSelected, { datasource: this.datasourceSelected, item: this.itemSelected, location }];
        } else {
          this.locationSelected = [...this.locationSelected.slice(0, index), ...this.locationSelected.slice(index + 1)];
        }
      } else if (this.mode === 'single') {
        // In single mode
        // Assign value if not exist
        // Else set is empty array
        if (index === -1) {
          this.locationSelected = [{ datasource: this.datasourceSelected, item: this.itemSelected, location }];
        } else {
          this.locationSelected = [];
        }
      }
    },
    checkAll() {
      if (this.allChecked === CHECKBOX.UNCHECK) {
        // SELECT ALL
        this.locationSelected = [...this.locationSelected, ...this.locations.map((location) => ({ datasource: this.datasourceSelected, item: this.itemSelected, location }))];
      } else {
        // DESELECT ALL
        this.locationSelected = this.locationSelected.filter((l) => l.datasource !== this.datasourceSelected || l.item !== this.itemSelected);
      }
    },
    statisticLocationsVariant(datasource) {
      let count = this.statisticLocationsCount(datasource);
      let total = this.statisticLocationsTotal(datasource);

      if (count === 0) return 'light-secondary';
      if (count === total) return 'light-success';
      return 'light-primary';
    },
    statisticLocationsTotal(datasourceId) {
      let datasource = this.datasources.find((d) => d.id === datasourceId);
      let datasourceMeta = this.$db[datasource.id];
      if (!datasourceMeta) return 0;
      let locations = datasourceMeta.locations;
      if (!locations) return 0;
      else return Object.keys(locations).length;
    },
    statisticLocationsCount(datasourceId) {
      return [...new Set(this.locationSelected.filter((l) => l.datasource === datasourceId).map((l) => l.location))].length;
    },
    itemTotal(datasourceId) {
      let datasource = this.datasources.find((d) => d.id === datasourceId);
      let datasourceMeta = this.$db[datasource.id];
      if (!datasourceMeta) return 0;

      let items = datasourceMeta.items;
      if (!items) return 0;
      else return Object.keys(items).length;
    },
    itemCount(datasource) {
      return [...new Set(this.locationSelected.filter((l) => l.datasource === datasource).map((l) => l.item))].length;
    },
    itemVariant(datasource) {
      let count = this.itemCount(datasource);
      let total = this.itemTotal(datasource);

      if (count === 0) return 'light-secondary';
      if (count === total) return 'light-success';
      return 'light-primary';
    },
    itemUnit(item) {
      let datasource = this.datasources.find((d) => d.id === this.datasourceSelected);
      let datasourceMeta = this.$db[datasource.id];
      if (!datasourceMeta) return '';

      let unit = datasourceMeta.items[item].unit;
      return unit ? `(${unit})` : '';
    },
    locationTotal(item) {
      let datasourceMeta = this.$db[this.datasourceSelected];
      if (!datasourceMeta) return 0;
      return Object.keys(datasourceMeta.columns).reduce((previous, element) => {
        let [l, i] = element.split('*');
        if (i === item) previous.push(l);
        return previous;
      }, []).length;
    },
    locationCount(item) {
      return [...new Set(this.locationSelected.filter((l) => l.datasource === this.datasourceSelected && l.item === item).map((l) => l.location))].length;
    },
    locationVariant(item) {
      let count = this.locationCount(item);
      let total = this.locationTotal(item);

      if (count === 0) return 'light-secondary';
      if (count === total) return 'light-success';
      return 'light-primary';
    },
    showStatisticData(datasourceId) {
      const isStatisticsData = this.checkStatisticData(datasourceId);
      return isStatisticsData && (this.$route.name === 'boxplot' || this.$route.name === 'boxplot-uplot');
    },
  },
};
</script>

<style scoped>
.datasource-select-container {
  padding: 0;
  position: relative;
  width: 100%;
  border: none;
  outline: none;
  cursor: default !important;
  background-color: rgba(57, 123, 255, 0.12) !important;
  border-radius: 0.357rem;
  color: #5e5873;
}
.datasource-select-container:disabled .datasource-pointer {
  cursor: not-allowed !important;
}

.dark-layout .datasource-select-container {
  color: #d0d2d6;
}

.datasource-select-container .datasource-pointer {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.datasource-select-container .datasource-title {
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}

.datasource-select-container .datasource-description {
  color: #397bff;
}

.datasource-select-container .datasource-select {
  position: absolute;
  left: 0;
  top: 100%;
  transform: scale(0);
  opacity: 0;
  transform-origin: 150px 0;
  z-index: 3;
  border-radius: 7px;
  box-shadow: rgb(0 0 0 / 25%) 0px 25px 50px -12px;
  padding: 10px;
  width: 634px;
  transition: transform 0.4s, opacity 0.4s;
  margin-top: 10px;
  backdrop-filter: blur(2px);
  background-color: rgba(57, 123, 255, 0.12) !important;
}

.datasource-select-container:focus .datasource-select {
  transform: scale(1);
  opacity: 1;
}
.datasource-select-container:focus-within .datasource-select {
  transform: scale(1);
  opacity: 1;
}

.datasource-select-container:focus .datasource-select:hover {
  opacity: 1;
}
.datasource-select-container:focus-within .datasource-select:hover {
  opacity: 1;
}

.datasource-select-container table {
  width: 100%;
  border-radius: 7px;
  overflow: hidden;
}

table {
  background-color: #fff;
}

.datasource-select-container th {
  padding: 10px;
  border: none;
  text-align: left;
  text-transform: uppercase;
}

.datasource-select-container td {
  padding: 0;
  border: none;
  text-align: left;
  max-width: 200px;
  vertical-align: top;
  border-right: 2px solid #f3f2f7 !important;
}

.dark-layout .datasource-select-container td {
  border-right: 2px solid #343d55 !important;
}

.datasource-select-container td:last-child {
  border-right: none !important;
}

.datasource-select-container .datasource-option {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.datasource-select-container .datasource-option:hover {
  background-color: #eeedfd;
}

.datasource-select-container .datasource-option.active {
  background-color: #d8dffd;
}

.dark-layout .datasource-select-container .datasource-option:hover {
  background-color: #31375a;
}

.dark-layout .datasource-select-container .datasource-option.active {
  background-color: #323f6e;
}

.datasource-select-container .datasource-option .datasource-content {
  flex-grow: 1;
  min-width: 0;
}

.datasource-select-container .datasource-option .datasource-content .datasource-name {
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.datasource-select-container .item-option {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.datasource-select-container .item-option:hover {
  background-color: #eeedfd;
}

.datasource-select-container .item-option.active {
  background-color: #d8dffd;
}

.dark-layout .datasource-select-container .item-option:hover {
  background-color: #31375a;
}

.dark-layout .datasource-select-container .item-option.active {
  background-color: #323f6e;
}

.datasource-select-container .item-option .item-content {
  flex-grow: 1;
  min-width: 0;
}

.datasource-select-container .item-option .item-content .item-name {
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.datasource-select-container .count-label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.datasource-select-container .custom-scrollbar {
  min-height: 52px;
  max-height: 500px;
  overflow-y: auto;
}
</style>

<style>
.datasource-select-container .custom-control-input ~ .custom-control-label {
  cursor: pointer;
}
</style>
