<template>
  <div class="h-100 common-datasource-tools merge-modal">
    <!-- <b-modal size="xl" title="Merge Tool" centered ok-only :ok-title="$t('save')" :hide-footer="true" ref="mergeModal" :modal-class="'merge-modal'" :body-class="'custom-body'" button-size="sm" @show="resetState">
    <template #modal-header> -->
    <div class="tools-header">
      <b-form-group class="mb-0">
        <label class="text-primary tool-custom-label">{{ $t('file_name') }}</label>
        <b-form-input :placeholder="$t('file_name')" v-model.trim="filename" size="sm" style="height: 28px" />
      </b-form-group>
      <b-form-group class="ml-auto mb-0">
        <b-button variant="success" size="sm" style="padding: 14px 28px" :disabled="!saveable" @click="save">{{ $t('save') }}</b-button>
      </b-form-group>
    </div>
    <!-- <button type="button" class="close" @click="close">&times;</button>
    </template> -->
    <div class="tools-body">
      <div class="d-flex h-100">
        <div class="file-manager">
          <div class="d-flex justify-content-between align-items-center" style="margin-bottom: 1rem">
            <h3 class="mb-0" style="line-height: 30px">{{ $t('imported_files') }}</h3>
            <b-button variant="flat-success" size="sm" @click="openFile" style="padding: 7px; background-color: rgba(40, 199, 111, 0.12); margin-right: 5px">
              <feather-icon icon="UploadIcon" />
            </b-button>
            <input type="file" ref="file" style="display: none" @change="addFile" accept=".csv" />
          </div>
          <div class="file-container custom-scrollbar">
            <Draggable v-model="files" handle=".handle" :animation="200" @start="drag = true" @end="drag = false">
              <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                <div :class="`file-item bg-light-${file.variant}`" v-for="file in files" :key="file.id">
                  <b-media no-body class="align-items-center file-media">
                    <b-media-aside class="handle" style="margin-right: 10px; cursor: grab">
                      <b-avatar rounded size="42" :variant="`light-${file.variant}`">
                        <feather-icon size="18" icon="MenuIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body style="text-align: left">
                      <p class="mb-0" :title="file.name">{{ file.name }}</p>
                    </b-media-body>
                  </b-media>
                  <b-badge pill :variant="`light-${file.variant}`" style="margin-right: 10px; cursor: pointer" @click="deleteFile(file.id)">
                    <feather-icon size="18" icon="Trash2Icon" />
                  </b-badge>
                </div>
              </transition-group>
            </Draggable>

            <TimeseriesSelect v-model="selected" :width="250" :closeAfterSelect="true" :blackList="files.map((f) => f.id)" />
          </div>
        </div>
        <div style="flex-grow: 1; min-width: 0">
          <DatatableBody label="merge" :modalType="null" :action="null" :rowsData="null" :filteredIndex="null" :columns="columns" :updateDatatable="updateDatatable" @getData="getDataTable" :itemFilterLength="itemFilterLength" :filtered="filteredData" :itemLength="itemLength" @edit-header="editHeader" />
        </div>
      </div>
    </div>
    <!-- </b-modal> -->
  </div>
</template>

<script>
import Encoding from 'encoding-japanese';
const _ = require('lodash');
const dayjs = require('dayjs');
import Papa from 'papaparse';
import Draggable from 'vuedraggable';
import FileMixin from '@/mixins/FileMixin.js';
import DatatableBody from '../tools/DatatableBody.vue';
import TimeseriesSelect from './TimeseriesSelect.vue';
import { v4 as uuidv4 } from 'uuid';
import { convertNumber } from '@/utilities/NumberUtility.js';
import { ACTION_LOG } from '@/constants/actionLog';
export default {
  mixins: [FileMixin],
  components: { DatatableBody, TimeseriesSelect, Draggable },
  mounted() {
    this.rows = [];
  },
  data() {
    return {
      drag: false,
      files: [],
      columns: [],
      filename: '',
      selected: null,

      itemFilterLength: 0,
      itemLength: 0,
      filteredData: [],
      dataFilterStore: [],
      updateDatatable: 0, //update view datatableBody when rows change(add column, ...)
    };
  },
  computed: {
    datasources() {
      return this.$store.state.datasource.datasources;
    },
    saveable() {
      if (!this.files.length) return false;

      for (let i = 0; i < this.columns.length; i++) {
        if (!this.columns[i].label[0]) return false;
        if (!this.columns[i].label[2]) return false;
      }

      if (!this.filename) return false;

      return true;
    },
    datasourceTools() {
      return this.$store.state.map.datasourceTools;
    },
  },
  watch: {
    files(files, oldFiles) {
      if (files.length > oldFiles.length) {
        let fileToAdd = files.find((f) => !oldFiles.some((o) => o.id === f.id));
        // add column
        if (this.columns.length === 0) {
          this.columns.push({ label: ['ID', 'GROUP', 'ITEM', 'UNIT'], field: 'date' });
        }
        let columns = [];
        let placeholder = ['enter_location', 'enter_group', 'enter_item', 'enter_unit'];
        const LOCATION_IDX = fileToAdd.header.findIndex((c) => c[0] === 'ID');
        const GROUP_IDX = fileToAdd.header.findIndex((c) => c[0] === 'GROUP');
        const ITEM_IDX = fileToAdd.header.findIndex((c) => c[0] === 'ITEM');
        const UNIT_IDX = fileToAdd.header.findIndex((c) => c[0] === 'UNIT');

        for (let i = 1; i < fileToAdd.header[0].length; i++) {
          columns.push({ label: [fileToAdd.header[LOCATION_IDX][i], fileToAdd.header[GROUP_IDX][i], fileToAdd.header[ITEM_IDX][i], fileToAdd.header[UNIT_IDX][i]], field: `${fileToAdd.id}-${i}`, type: 'number', editable: true, placeholder });
        }
        let oldColumns = this.columns.filter((c) => c.field !== 'date');
        this.columns = [...this.columns, ...columns];

        // add row
        let oldRows = _.cloneDeep(this.rows);

        let newRows = fileToAdd.body.map((r) => {
          let data = { date: r[0] };
          columns.forEach((column, index) => {
            data[column.field] = r[index + 1];
          });
          return data;
        });

        for (let i = 0; i < oldRows.length; i++) {
          let data = newRows.find((r) => r.date === oldRows[i].date);
          columns.forEach((column) => {
            oldRows[i][column.field] = data ? data[column.field] : null;
          });
        }

        let rowToAdd = _.differenceBy(newRows, oldRows, (el) => el.date);
        for (let i = 0; i < rowToAdd.length; i++) {
          oldColumns.forEach((column) => {
            rowToAdd[i][column.field] = null;
          });
        }

        this.rows = _.sortBy([...oldRows, ...rowToAdd], ['date']);
      } else if (oldFiles.length > files.length) {
        let fileToDelete = oldFiles.find((o) => !files.some((f) => f.id === o.id));

        // delete column
        this.columns = this.columns.filter((c) => !c.field.startsWith(fileToDelete.id));
        if (this.columns.length === 1) {
          this.columns = [];
        }

        // delete row
        let rows = [];
        for (let i = 0; i < this.rows.length; i++) {
          let data = {};
          this.columns.forEach((column) => {
            data[column.field] = this.rows[i][column.field];
          });
          rows.push(data);
        }

        let dateToKeep = [];
        files.forEach((file) => {
          dateToKeep.push(...file.body.map((r) => ({ date: r[0] })));
        });

        this.rows = _.intersectionBy(rows, dateToKeep, 'date');
      } else {
        if (!this.columns.length) return;

        // sort column
        let columns = [{ label: ['ID', 'GROUP', 'ITEM', 'UNIT'], field: 'date' }];
        this.files.forEach((file) => {
          this.columns.forEach((column) => {
            if (column.field.startsWith(file.id)) {
              columns.push(column);
            }
          });
        });
        this.columns = columns;
      }
      this.updateDatatable++;
      this.itemLength = this.rows.length;
    },
    async selected(selected) {
      if (!selected) return;

      let datasource = this.datasources.find((d) => d.id === selected);

      if (!datasource) return;

      let header = [['ID'], ['GROUP'], ['ITEM'], ['UNIT']];
      let body;
      let dataTmp = await this.selectAll(datasource.id);
      let convertData = {};
      convertData['dates'] = dataTmp['dates'];
      for (const key in this.$db[datasource.id].columns) {
        let [location, item] = key.split('*');
        header[0].push(location);
        header[1].push(this.$db[datasource.id].columns[key].autoGroup);
        header[2].push(item);
        header[3].push(this.$db[datasource.id].columns[key].unit);
        convertData[`${location}-${item}`] = dataTmp[`${location}-${item}`];
      }
      body = this.transpose(Object.values(convertData));
      this.files = [...this.files, { id: datasource.id, name: datasource.name, header, body, variant: 'primary' }];
    },
    datasourceTools() {
      this.resetState();
    },
  },
  methods: {
    transpose(matrix) {
      return matrix[0].map((_, columnIndex) => matrix.map((row) => row && row[columnIndex] != null ? row[columnIndex] : null));
    },
    filtered(dataFilter, searchTerm) {
      let filtered = dataFilter.filter((row) => {
        let valid = false;
        for (const key in row) {
          let value = row[key] === null ? 'NA' : row[key].toString();
          if (value.toLowerCase().includes(searchTerm.toLowerCase())) {
            valid = true;
            break;
          }
        }
        return valid;
      });
      return filtered;
    },
    getDataTable(searchTerm, from, to, isGetDataToSearch) {
      if (searchTerm === '') {
        this.itemFilterLength = this.itemLength;
        this.filteredData = this.filtered(this.rows.slice(+from, +to), searchTerm);
      } else {
        if (isGetDataToSearch) this.dataFilterStore = this.filtered([...this.rows], searchTerm);
        if (!this.dataFilterStore.length) {
          from = from === -1 ? 0 : from;
          to = to === 0 ? 20 : to;
          this.itemFilterLength = this.itemLength;
          this.filteredData = this.filtered(this.rows.slice(+from, +to), '');
        } else {
          this.itemFilterLength = this.dataFilterStore.length;
          this.filteredData = this.dataFilterStore.slice(+from, +to);
        }
      }
    },
    resetState() {
      this.files = [];
      this.columns = [];
      this.rows = [];
      this.filename = '';
    },
    // open() {
    //   this.$refs.mergeModal.show();
    // },
    // close() {
    //   this.$refs.mergeModal.hide();
    // },
    openFile() {
      this.$refs.file.click();
    },
    async getEncodingFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async function (e) {
          try {
            let codes = new Uint8Array(e.target.result);
            let encoding = Encoding.detect(codes); // Detech file encoding
            resolve(encoding);
          } catch {}
          resolve('UTF8');
        };
        reader.readAsArrayBuffer(file);
      });
    },
    async addFile() {
      let file = this.$refs.file.files[0];
      let encoding='UTF8';
      try {
        encoding = await this.getEncodingFile(file);
      } catch {}
      Papa.parse(file, {
        skipEmptyLines: true,
        encoding: encoding,
        complete: (result) => {
          let csv = result.data;
          let header = csv.filter((row) => ['ID', 'GROUP', 'ITEM', 'UNIT'].includes(row[0]));

          let body = csv.filter((row) => dayjs(row[0]).isValid());

          if (body.length) {
            let rowLength = body[0].length;
            if (!header.some((row) => row[0] === 'ID')) {
              header.unshift(['ID', ...Array(rowLength - 1).fill('')]);
            }
            if (!header.some((row) => row[0] === 'GROUP')) {
              header.push(['GROUP', ...Array(rowLength - 1).fill('')]);
            }
            if (!header.some((row) => row[0] === 'UNIT')) {
              header.push(['UNIT', ...Array(rowLength - 1).fill('')]);
            }
            if (!header.some((row) => row[0] === 'ITEM')) {
              header.splice(1, 0, ['ITEM', ...Array(rowLength - 1).fill('')]);
            }

            body = body.map((row) => row.map((cell, index) => (index ? convertNumber(cell) : dayjs(cell).format('YYYY-MM-DD HH:mm:ss'))));
            this.files = [...this.files, { id: uuidv4(), name: file.name, header, body, variant: 'success' }];
          }

          this.$refs.file.value = '';
        },
      });
    },
    deleteFile(id) {
      this.files = this.files.filter((f) => f.id !== id);
    },
    editHeader({ field, index, value }) {
      let colIndex = this.columns.findIndex((col) => col.field === field);
      if (colIndex !== -1) {
        let newCol = _.cloneDeep(this.columns[colIndex]);
        newCol.label[index] = value.trim();
        this.columns.splice(colIndex, 1, newCol);
      }
    },
    async save() {
      // Convert array of row object to 1 datasource object and add to store
      let id = uuidv4();
      let name = this.filename + (this.filename.endsWith('.csv') ? '' : '.csv');
      let type = 'timeseries';
      let dates = this.rows.map((r) => r.date);
      let data = {};
      let autoGroups = {};
      let datasourceData = [];
      let datasourceColumns = {};
      let tmpGroup = null; // temp variable
      const GROUP_IDX = this.columns[0].label.findIndex((c) => c === 'GROUP');
      const ITEM_IDX = this.columns[0].label.findIndex((c) => c === 'ITEM');
      const UNIT_IDX = this.columns[0].label.findIndex((c) => c === 'UNIT');
      // Các cột ngoại trừ cột date
      let columns = this.columns.map((c) => c.field).filter((c) => c !== 'date');
      columns.forEach((column, index) => {
        // TODO: real calculate 'nullCount'
        let tmpName = this.columns[index + 1].label[0] + '*' + this.columns[index + 1].label[ITEM_IDX];
        if (Object.keys(datasourceColumns).includes(tmpName)) tmpName = `${this.columns[index + 1].label[0]}_1*${this.columns[index + 1].label[ITEM_IDX]}`;
        datasourceColumns[tmpName] = { nullCount: 0, autoGroup: this.columns[index + 1].label[GROUP_IDX], unit: this.columns[index + 1].label[UNIT_IDX] };
        // Build autoGroup
        tmpGroup = GROUP_IDX !== -1 ? this.getGroupName(this.columns[index + 1].label[GROUP_IDX]) : [];
        tmpGroup.map((grName) => {
          if (!grName) return;
          if (!autoGroups[grName]) autoGroups[grName] = [];
          autoGroups[grName].push(tmpName);
        });
      });
      // Build data
      this.rows.forEach((row) => {
        let rowData = [];
        rowData.push(row.date);
        columns.forEach((column, index) => {
          rowData.push(row[column]);
        });
        datasourceData.push(rowData);
      });

      //Build data
      const keysCol = [...Object.keys(datasourceColumns)];
      for (let i = 0; i < keysCol.length; i++) {
        for (let j = 0; j < datasourceData.length; j++) {
          if (!data[keysCol[i].replace('*', '-')]) data[keysCol[i].replace('*', '-')] = [];
          data[keysCol[i].replace('*', '-')].push(datasourceData[j][i + 1]);
        }
      }
      // Build Items
      let items = {};
      let itemArray = [...new Set(this.columns.slice(1).map((column) => column.label[ITEM_IDX]))];
      itemArray.forEach((item) => {
        if (!item) return;
        let obj = { min: Infinity, max: -Infinity, unit: '' };
        // Min & max
        for (const key in data) {
          if (key.endsWith(`-${item}`)) {
            for (let i = 0; i < data[key].length; i++) {
              if (data[key][i] !== null && data[key][i] < obj.min) obj.min = data[key][i];
              if (data[key][i] !== null && data[key][i] > obj.max) obj.max = data[key][i];
            }
          }
        }
        // Unit
        let index = this.columns.findIndex((column) => column.label[ITEM_IDX] === item);
        obj.unit = this.columns[index].label[UNIT_IDX];
        items[item] = obj;
      });

      // Build Locations
      let locations = {};
      let locationArray = [...new Set(this.columns.slice(1).map((column) => column.label[0]))];
      locationArray.forEach((location) => {
        if (!location) return;
        let obj = {};
        locations[location] = obj;
      });

      let datasourceObject = { id, name, type, storage_mode: 'RAM', columns: datasourceColumns, dates, locations, items, csvData: datasourceData, data, header: [...this.columns[0].label], autoGroups };
      await this.storeData(datasourceObject);
      const file = await this.createObjectFile(datasourceObject);
      datasourceObject.file = file;
      datasourceObject.size = file.size;
      await this.addDatasource(datasourceObject);
      this.updateDatatable++;
      let messageTitle = 'create_from_merge';
      let message = this.$t(messageTitle, {move: name});
      this.log.info({ message, id: ACTION_LOG[7].id, messageTitle, move: name });
      // this.log.info({ message: `Create and add datasource '${name}' from datasource tool [Merge]`, id: ACTION_LOG[7].id });
      // this.close();
    },
    getGroupName(str) {
      return str.toString().split('|');
    },
  },
};
</script>

<style>
.tool-custom-radio label {
  line-height: 14px !important;
  padding: 6px 14px !important;
}

.tool-custom-label {
  display: block;
  line-height: 10px;
  margin-bottom: 3px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
}
.merge-modal .file-manager {
  width: 255px;
  flex-shrink: 0;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
}

.merge-modal .file-container {
  height: calc(100vh - 255px);
  overflow-y: scroll;
}

.merge-modal .file-name {
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: auto;
  margin-bottom: 0;
}

.merge-modal .file-icon {
  cursor: pointer;
  flex-shrink: 0;
}

.merge-modal .file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.357rem;
  margin-bottom: 6px;
}

.merge-modal .file-media {
  min-width: 0;
  flex-grow: 1;
  margin-right: 10px;
}

.merge-modal .file-media .media-body {
  min-width: 0;
}

.merge-modal .file-media p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
